export type IHasId = {id: string};
export type WithId<T> = T & IHasId;

export type KeyedData<T> = {[k: string]: T};

export class KeyedArray<T extends IHasId> {
  public mp: KeyedData<number>;
  public data: (T | null)[]; // null indicates delete item

  private _numHoles: number;

  constructor() {
    this.mp = {};
    this.data = [];

    this._numHoles = 0;
  }

  public contains(id: string): boolean {
    return this.mp[id] !== undefined && this.mp[id] >= 0;
  }

  public get(id: string): T | null {
    return this.contains(id) ? this.data[this.mp[id]]: null;
  }

  public set(data: T[]) {
    this.clear();

    this.data = data;
    data.forEach((d, i) => this._addToMp(d.id, i));
  }

  public add(item: T) {
    if (!this.contains(item.id)) {
      this.data.push(Object.assign({}, item as T)); // new item always ends up at the bottom.
      this._addToMp(item.id, this.data.length - 1);
    }
  }

  public delete(item: T) {
    if (this.mp[item.id]) {
      this._lazydelete(item);

      if (this._numHoles >= this.data.length * 2)
        this._fixData();
    }
  }

  // this makes so that this.data has null in random indices.
  // user must filter out null values.
  private _lazydelete(item: T) {
    const index = this.mp[item.id];
    delete this.mp[item.id];

    if (index === this.data.length - 1) {
      this.data.pop();
    } else {
      this.data[index] = null;
      this._numHoles++;
    }
  }

  private _fixData() {
    // even if we remove holes in-place, we must do additional O(n) pops
    // so just create a new array.
    const dataNew = this.data.filter(d => d !== null);

    this.clear();
    this.data = dataNew;
  }

  private _addToMp(id: string, index: number) {
    this.mp[id] = index;
  }

  public clear() {
    this.mp = {};
    this.data = [];
    this._numHoles = 0;
  }
}

// cacheKey, as well as a base dependency to the manager.
export type CacheKey = string | IHaveKey;
export type IHaveKey = { GetKey: () => string };

export type ItemResult<T> = {data: T[], hasChange: boolean};

export function AsBoolean(p: Promise<void>): Promise<boolean> {
  return p.then(() => {
    return true;
  }).catch(err => {
    console.error(err.stack);
    return false;
  })
}

export function GetScrollbarSize(): number {
  const size = (window as any).scrollbarSize;
  return size ? size : 0;
}

// avoid invalid document id
export function EncodeDocId(st: string): string {
  if (st === '.')
    return '%2E';
  if (st === '..')
    return '%2E%2E';

  // __.*__ is not allowed (just encode _ always)
  return st.replace('_', '%2X').replace(/\//g, '%2F');
}

export function DecodeDocId(st: string): string {
  if (st === '%2E')
    return '.';
  if (st === '%2E%2E')
    return '..';

  return st.replace('%2F', '/').replace('%2X', '_');
}

export function ShowModal(message: string) {
  window.confirm(message);
}

export const FIn = (tt: number, t: number): boolean => (tt & t) === t;

// This means that the image itself should be 22x22 (including padding).
export const StyleIcon = {borderWidth: 2, borderRadius: 8, width: 26, height: 26};