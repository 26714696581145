const numberOfModifiers = 0x1C; //28
const numberOfVowels = 0x15; // 21
const numberOfWords = numberOfVowels*numberOfModifiers; // 588
const unicodeStart = 0xAC00; // '가'
const unicodeEnd = 0xD7A3; // '힣'

const unicodeConsonantStart = 0x3131;
const unicodeConsonantEnd = 0x314E;

// TODO move this serverside, add tests for starting with vowels.
interface MapNumber {
  [k : number] : number;
}

interface MapIndex {
  [ch : string] : number;
}

interface MapChars {
  [ch : string] : string[];
}

// interface MapChar {
//   [ch : string] : string;
// }

const krWordMap : MapNumber = {
  0: 28, // 가 ~ 갛
  1: 4, // 각 갂 갃; this is fine since 갂 isn't used.
  4: 7, // 간 갅 갆
  // 7: 8, // 갇
  8: 16, // 갈 갉 갊 갋 갌 갍 갎 갏
  // 16: 17, // 감
  17: 19, // 갑 값
  // 19: 20, // 갓
  // 20: 21, // 갔
  // 21: 22, // 강
  // 22: 23, // 갖
  // 23: 24, // 갗
  // 24: 25, // 갘
  // 25: 26, // 같
  // 26: 27, // 갚
  // 27: 28, // 갛
};

// const krConsonant

// return the index as initial consonant.
const krInitialConsonant : MapIndex = {
  ㄱ: 0,
  ㄲ: 1,
  ㄴ: 2,
  ㄷ: 3,
  ㄸ: 4,
  ㄹ: 5,
  ㅁ: 6,
  ㅂ: 7,
  ㅃ: 8,
  ㅅ: 9,
  ㅆ: 10,
  ㅇ: 11,
  ㅈ: 12,
  ㅉ: 13,
  ㅊ: 14,
  ㅋ: 15,
  ㅌ: 16,
  ㅍ: 17,
  ㅎ: 18,
}

const krFinalConsonant : MapIndex = {
  ㄱ: 1,
  ㄲ: 2,
  ㄳ: 3,
  ㄴ: 4,
  ㄵ: 5,
  ㄶ: 6,
  ㄷ: 7,
  ㄹ: 8,
  ㄺ: 9,
  ㄻ: 10,
  ㄼ: 11,
  ㄽ: 12,
  ㄾ: 13,
  ㄿ: 14,
  ㅀ: 15,
  ㅁ: 16,
  ㅂ: 17,
  ㅄ: 18,
  ㅅ: 19,
  ㅆ: 20,
  ㅇ: 21,
  ㅈ: 22,
  ㅊ: 23,
  ㅋ: 24,
  ㅌ: 25,
  ㅍ: 26,
  ㅎ: 27,
}

const krSplitFinalConsonant : MapChars = {
  ㄳ: ['ㄱ', 'ㅅ'],
  ㄵ: ['ㄴ', 'ㅈ'],
  ㄶ: ['ㄴ', 'ㅎ'],
  ㄺ: ['ㄹ', 'ㄱ'],
  ㄻ: ['ㄹ', 'ㅁ'],
  ㄼ: ['ㄹ', 'ㅂ'],
  ㄽ: ['ㄹ', 'ㅅ'],
  ㄾ: ['ㄹ', 'ㅌ'],
  ㄿ: ['ㄹ', 'ㅍ'],
  ㅀ: ['ㄹ', 'ㅎ'],
  ㅄ: ['ㅂ', 'ㅅ'],
}

class _util {
  public static IsKorean(ch : number) : boolean {
    return _util.IsKoreanConsonant(ch) || (ch >= unicodeStart && ch <= unicodeEnd);
  }

  public static IsKoreanConsonant(ch : number) : boolean {
    return ch >= unicodeConsonantStart && ch <= unicodeConsonantEnd;
  }

  public static GetLastCh(st : string) : number {
    return st.charCodeAt(st.length-1);
  }

  // ㄱ => [가, 까); includes all that starts with ㄱ
  public static GetRangeFromConsonant(charCode : number) : number[] | null {
    // assert _util.IsKoreanConsonant(ch) == true
    // count: number of korean letters that starts with given ch.
    // must use the mapping because the order of these consonants are different
    const ch = String.fromCharCode(charCode);

    // e.g. ㄳ; there is no word that starts with ㄳ
    if (krInitialConsonant.hasOwnProperty(ch)) {
      const indexConsonant = krInitialConsonant[ch];
      const charCodeFirst = unicodeStart + (indexConsonant*numberOfWords);
      const charCodeLast = unicodeStart + ((indexConsonant+1)*numberOfWords);
      return [charCodeFirst, charCodeLast];
    }

    return null;
  }

  // 갑 => 갓; e.g. 갑분싸, 값
  public static GetLetterSuccessor(charCode : number) : number[] { 
    const k = (charCode-unicodeStart)%numberOfModifiers;
    const offset = krWordMap.hasOwnProperty(k) ? krWordMap[k]-k : 1;
    return [charCode, charCode + offset];
  }

  private static HandleConsonant(ranges : string[][], st : string) : void {
    const charCode = st.charCodeAt(st.length-1);
    const ch = String.fromCharCode(charCode);
    const front = st.substring(0, st.length-1);
    // todo: assert consonant
    // _util.IsKoreanConsonant(charCode)

    // [ㄱ, ㄲ)
    // e.g. ㄳ; there is no word that starts with ㄳ
    const k = krFinalConsonant[ch];
    let offset = krWordMap.hasOwnProperty(k) ? krWordMap[k]-k : 1;
    
    // Special Case:
    if (ch === 'ㅂ')
      offset++;

    ranges.push(_util.ChFromCharCode(
      front,
      [charCode, charCode+offset]
    ));

    // [가, 까)
    const chars = _util.GetRangeFromConsonant(charCode);
    if (chars !== null) {
      ranges.push(_util.ChFromCharCode(
        front,
        chars
      ));
    }
  }

  // for prefix search; returns [a, b]
  // s.t. finding string with x >= a and x < b returns all string that 'starts' with st.
  public static getRange(st : string) : string[][] {
    const charCode = st.charCodeAt(st.length-1);
    const ch = String.fromCharCode(charCode);
    const front = st.substring(0, st.length-1);
    const ranges : string[][] = [];

    // const debug = [];

    if (_util.IsKoreanConsonant(charCode)) {
      _util.HandleConsonant(ranges, st);

      if (krSplitFinalConsonant.hasOwnProperty(ch)) {
        // ㄳ: => ㄱㅅ
        const splitted = krSplitFinalConsonant[ch];
        _util.HandleConsonant(ranges, `${front}${splitted[0]}${splitted[1]}`);
      }
    } else {
      const k = (charCode-unicodeStart)%numberOfModifiers;
      const offset = krWordMap.hasOwnProperty(k) ? krWordMap[k]-k : 1;
      ranges.push(_util.ChFromCharCode(
        front,
        [charCode, charCode + offset])
      );

      // if it has a final consonant
      if (k > 0) {
        const charCodeBase = unicodeStart + Math.floor((charCode-unicodeStart)/numberOfModifiers); // e.g. get 가 if 각.
        const indexFinalConsonant = _util.EnsureIndexLastConsonant(charCode - charCodeBase);
  
        let charCodeSplitted = charCodeBase;
        let nextConsonant = String.fromCharCode(unicodeConsonantStart + indexFinalConsonant - 1);
        if (krSplitFinalConsonant.hasOwnProperty(nextConsonant))
        {
          charCodeSplitted += krFinalConsonant[krSplitFinalConsonant[nextConsonant][0]];
          nextConsonant = krSplitFinalConsonant[nextConsonant][1];
        }
  
        // ranges.push(
        //   [
        //     `${String.fromCharCode(charCode)}`,
        //     `${String.fromCharCode(charCodeBase)}`,
        //     nextConsonant,
        //     `${indexFinalConsonant}`,
        //   ]
        // );

        const chars = _util.GetRangeFromConsonant(nextConsonant.charCodeAt(0));
        if (chars !== null) {
          ranges.push(_util.ChFromCharCode(
            `${front}${String.fromCharCode(charCodeSplitted)}`,
            chars
          ));  
        }
      }
    }

    // add the prefix
    // ranges.push(debug);
    return ranges;
  }

  private static EnsureIndexLastConsonant(index : number) : number {
    if (index >= 8)
      index += 1;
    if (index >= 19)
      index += 1;
    if (index >= 25)
      index += 1;

    return index;
  }

  private static ChFromCharCode(front : string, charCodes : number[]) : string[] {
    return charCodes.map((charCode)=>`${front}${String.fromCharCode(charCode)}`);
  }
}

const Korean = {
  isKorean: _util.IsKorean,
  isConsonant: _util.IsKoreanConsonant,
  getSuccessorRange: _util.getRange,
};

export default Korean;


// ㄱ krMap for consonants
// disable ㄳ, etc 
// 초성 검색; create index.
// handle 과작 to search for 과자ㄱ 