import React from 'react';

interface TimedProps {
  keyTime: string; // change in Key causes timer to start.
  duration: number;
  onTimeout?: () => void;
  renderContent?: (timeout: boolean) => React.ReactNode;
  renderEmpty: () => JSX.Element | null;
}

interface TStates {
  _cachedKey: string;
  show: boolean;
}

export type TimedParam<T> = { getKey: () => string, duration?: number, obj: T};

export default class Timed extends React.Component<TimedProps, TStates> {
  // Letting TS infer types correctly checks for required vs optional props.
  static defaultProps = {
    duration: 1000,
    renderEmpty: () => null,
  }

  static getDerivedStateFromProps(props: {keyTime: string}, states: TStates) {
    if (!props.keyTime) {
      return {
        _cachedKey: '',
        show: false,
      }
    } else if (states._cachedKey !== props.keyTime) {
      return {
        _cachedKey: props.keyTime,
        show: true
      }
    }

    return null;
  }

  private id: number | null;

  constructor(props: TimedProps) {
    super(props);

    this.state = {
      _cachedKey: '',
      show: true,
    }

    this.id = null;
  }

  render() {
    if (!this.props.renderContent && !this.props.children)
      return null;

    const renderContent = this.props.renderContent
      ? this.props.renderContent
      : (timeout: boolean) => timeout ? this.props.renderEmpty() : this.props.children;

    // if nothing to show, no need to start the timer.
    const timeout: boolean = !this.state.show;
    if (!timeout) {
      if (this.id !== null)
        clearTimeout(this.id);

      this.id = setTimeout(
        () => {
          if (this.props.onTimeout)
            this.props.onTimeout();

          this.setState({show: false});
        },
        this.props.duration
      ) as any; // not sure why it doesn't work without as any.
    }

    return renderContent(timeout);
  }
}