// Only useful for web
export default class Highlight {
  private iselected: number;

  constructor() {
    this.iselected = -1;
  }

  public IsSelected(i: number): boolean {
    return this.iselected === i;
  }

  public SetSelected(i: number) {
    this.iselected = i;
  }

  public RemoveSelection() {
    this.iselected = -1;
  }

  public SelectNext(array: any[]) {
    this.iselected++;
    if (this.iselected === array.length)
      this.iselected = 0;
  }

  public SelectPrev(array: any[]) {
    this.iselected--;
    if (this.iselected < 0)
      this.iselected = array.length - 1;
  }
}