import React from "react";
import { AppRegistry, Platform, View, ActivityIndicator, TouchableOpacity, StyleSheet, Dimensions } from "react-native";

import {T} from './components/T';
import UserBox from './components/userBox';
import SearchBox from './components/searchBox';
import {PageType, UserPage, SearchPage, StatPage} from './components/pages';

import Remote, {WordItem} from './lib/remote';
import {SignHelper, StatWordItem} from './lib/firebaseUtil';
import * as URL from './page';

import TestRunner from './testLib/tests';
import TestKorean from './testLib/test-korean';
import TestComponent from './components/testComponent';

const enableTest = false;//true;

const appName = "InsiderDictionary";
const remote = Remote.I;
const defaultPageType: PageType = PageType.Stat;

type ParamValue = {uid: string, query: string};

type MainProps = {};
type MainState = {
  page: PageType,

  param: { uid: string, query: string },

  username: string,
};

function IsLandscape() {
  return Platform.OS === 'web' && Dimensions.get("window").width > Dimensions.get("window").height;
}

// Param Helper
const Param = {
  fromQuery: (query: string) => {
    return {
      query,
      uid: ''
    };
  },
  fromUid: (uid: string) => {
    return {
      query: '',
      uid
    }
  },
  Nil: {
    query: '',
    uid: ''
  }
}

/*
  Events:
    - OnChange in URL Param:
      -> set query
      -> set page
    - OnSearch
      -> set query

  If query changes, data will be populated based on the new query.
*/
export class Main extends React.Component<MainProps, MainState> {
  private _refUserBox: React.RefObject<UserBox>;

  constructor(props : MainProps) {
    super(props);

    this._refUserBox = React.createRef();

    this.state = {
      page: PageType.Loading,
      param: Param.Nil,
      username: '',
    };

    // Register handlers:

    // whenever new user data is passed, update the results
    SignHelper.I.OnUserDataChange(this._refresh);

    // Only makes sense to change URL if we are WebApp.
    // Whenever browser back or forward gets hit, we must update query.
    if (Platform.OS === 'web') {
      window.onpopstate = () => this._setStateFromParam();
    }
  }

  componentDidMount() {
    this._setStateFromParam();
  }

  render() {
    const searchBoxHeight = 54;
    const {param, page} = this.state;
    return (
      <View style={{width: '80%', alignSelf: 'center'}}>
        <UserBox ref={this._refUserBox} remote={remote} />
        <View style={{marginTop: searchBoxHeight+20}}>
          <SearchBox
            style={{position: 'absolute', zIndex: 3, width: '100%', marginTop: -searchBoxHeight}}
            height={searchBoxHeight}
            remote={remote}
            query={param.query}
            onSearch={(query: string) => {
              remote.stat.Record['AddHistoryOnSearch'](query);
              this._onParamChange['query'](query);
            }}
          />
          <View style={{marginTop: 27}}></View>
          {this._renderPage[page]()}
        </View>
        {_test_render()}
      </View>
    );
  }

  private _refresh = () => this.setState({page: this.state.page});

  private _onParamChange: {[k in keyof ParamValue]: (value: ParamValue[k]) => void} = {
    uid: (uid) => {
      this._updateParamStateIfChanged.uid(uid).then(() => {
        if (Platform.OS === 'web') {
          URL.SetParam({uid});
        }
      });
    },
    query: (query) => {
      this._updateParamStateIfChanged.query(query).then(() => {
        if (Platform.OS === 'web') {
          URL.SetParam({q: query});
        }
      })
    },
  };

  private _updateParamStateIfChanged: {[k in keyof ParamValue]: (value: ParamValue[k], setParam?: boolean) => Promise<any>} = {
    uid: (uid) => {
      if (this.state.param.uid === uid)
        return Promise.resolve();

      return remote.GetPublicUserRecord(uid)
        .then(user => {
          this.setState({
            page: PageType.User,
            param: Param.fromUid(uid),
            username: user.alias,
          });
        })
        .catch(() => {
          this.setState({
            page: PageType.Error,
            param: Param.Nil,
          });
        });
    },
    query: (q) => {
      if (this.state.param.query === q)
        return Promise.resolve();

      // could technically wait for setState to finish
      // but is that necessary?
      return new Promise(resolve => {
        this.setState({
          page: PageType.Search,
          param: Param.fromQuery(q),
        });
        resolve();
      });
    },
  }

  // Take q, if not take uid, if not just show Search.
  private _setStateFromParam(){
    const params = URL.GetParam();
    if (params.q !== undefined) {
      this._updateParamStateIfChanged.query(params.q);
    } else if (params.uid !== undefined) {
      this._updateParamStateIfChanged.uid(params.uid);
    } else if (this.state.page !== defaultPageType) {
      this.setState({
        page: defaultPageType,
        param: Param.Nil,
        username: '',
      });
    }
  }

  // use static s.t. shallow comparison works.
  private static _statGetMostVoted = (): Promise<WordItem[]> => remote.stat.Get['MostVoted'](18);
  // private static _statGetRecentlyAdded = (): Promise<WordItem[]> => remote.stat.Get['RecentlyAdded'](18);
  private static _statGetPopularAlltime = (): Promise<StatWordItem[]> => remote.stat.Get['PopularAllTime'](18);
  // private static _statGetPopularToday = (): Promise<StatWordItem[]> => remote.stat.Get['PopularToday'](18);
  // private static _statGetPopularMonth = (): Promise<StatWordItem[]> => remote.stat.Get['PopularThisMonth'](18);

  private _renderPage: {[k in PageType]: () => JSX.Element} = {
    [PageType.Loading]: () => (<ActivityIndicator size={'large'} />),
    [PageType.Search]: () => (
      <SearchPage
        remote={remote}
        dep={this.state.param.query}
        showNominate={this._showNominate}
        highlightLogin={this._highlightLogin}
        onSelectUser={this._onParamChange['uid']}
        onSearch={this._onParamChange['query']}
      />
    ),
    [PageType.User]: () => (<UserPage remote={remote} dep={this.state.param.uid} username={this.state.username} />),
    [PageType.Stat]: () => (
      <View>
        <View style={styles.StatContainer}>
          <StatPage
            style={styles.StatPage}
            title={'많이 추천된 인싸용어'}
            remote={remote}
            dep={Main._statGetMostVoted}
            renderItem={this._renderTrendingItem}
          />
          <StatPage
            style={styles.StatPage}
            title={'많이 본 인싸용어'}
            remote={remote}
            dep={Main._statGetPopularAlltime}
            renderItem={this._renderPopularToday}
          />
        </View>
      </View>
    ),
    [PageType.Error]: () => (
      <View>
        <T style={{fontSize: 44, fontWeight: 'bold'}}>잘못된 페이지입니다.</T>
      </View>
    ),
  };

  private _showNominate = () => {
    const ref = this._refUserBox.current;
    if (ref)
      ref.ShowNominateForm();
  }

  private _highlightLogin = () => {
    const ref = this._refUserBox.current;
    if (ref)
      ref.HighlightLogin();
  }

  private _renderTrendingItem = (item: WordItem) => {
    const word = item.actual ? item.actual : item.word;
    return (
      <View style={{flexDirection:'row', justifyContent: 'space-between', paddingHorizontal: 10, paddingVertical: 4}}>
        <TouchableOpacity onPress={() => this._onParamChange['query'](word)} style={{flexDirection: 'row'}}>
          <T style={{fontSize: 18, maxWidth: 200}}>{word}</T>
          <T style={{fontSize: 10, alignSelf:'flex-end'}}>{` ${item.timestamp.toDate().toLocaleDateString()}`}</T>
        </TouchableOpacity>
        <T style={{fontSize: 18}}>{item.vote}</T>
      </View>
    );
  }

  // private _renderRecentItem = (item: WordItem) => {
  //   const word = item.actual ? item.actual : item.word;
  //   return (
  //     <View style={{flexDirection:'row', justifyContent: 'space-between', paddingHorizontal: 10, paddingVertical: 4}}>
  //       <TouchableOpacity onPress={() => this._onParamChange['query'](word)} style={{flexDirection: 'row'}}>
  //         <T style={{fontSize: 18, maxWidth: 200}}>{word}</T>
  //         <T style={{fontSize: 10, alignSelf:'flex-end'}}>{` ${item.timestamp.toDate().toLocaleDateString()}`}</T>
  //       </TouchableOpacity>
  //       <TouchableOpacity onPress={() => this._onParamChange['uid'](item.uid)}>
  //         <T style={{fontSize: 18}}>{item.addedBy}</T>
  //       </TouchableOpacity>
  //     </View>
  //   );
  // }

  private _renderPopularToday = (item: StatWordItem) => {
    const word = item.id;
    return (
      <View style={{flexDirection:'row', justifyContent: 'space-between', paddingHorizontal: 10, paddingVertical: 4}}>
        <TouchableOpacity onPress={() => this._onParamChange['query'](word)} style={{flexDirection: 'row'}}>
          <T style={{fontSize: 18, maxWidth: 200}}>{word}</T>
        </TouchableOpacity>
        <T style={{fontSize: 18}}>{item.count}</T>
      </View>
    );
  }
}

const _test_render = () => {
  // how to get all tests automatically.
  if (!enableTest)
    return null;

  return (
    <TestComponent
      data={
        TestRunner.Run(new TestKorean())
      }
    />
  );
}

const styles = StyleSheet.create({
  StatContainer: {
    flexDirection: IsLandscape() ? 'row' : 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  StatPage: {
    width: IsLandscape() ? 300 : '100%',
    backgroundColor: 'rgba(108,162,108,0.2)',
  },
});

AppRegistry.registerComponent(appName, () => Main);
if (Platform.OS === "web") {
  AppRegistry.runApplication(appName, {
    rootTag: document.getElementById("root")
  });
}