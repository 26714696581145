
import React, {Fragment, PropsWithChildren} from 'react';
import {View, TouchableOpacity, StyleProp, TextInputProps, ViewStyle, ActivityIndicator, Picker, StyleSheet} from 'react-native';
import {KeyedData, FIn} from '../lib/commons';
import {T, TI} from './T';
import {ResListProps, ResList, ResData} from './resList';
import {Selectable} from './box';

const _debug_formeHistory = false;

export type FormeDataWithInput<TInput, TValue> = {input: TInput, value: TValue};

type RefChild = undefined | React.RefObject<any>;
type FocusObj = {i: number, ref: RefChild};

type StageData = {type: StageType, info: StageInfo, props: PropsWithChildren<StageProps>, children: React.ReactNode};
type Validator = (k: string) => Promise<string | true>;
type Validity = {valid: boolean, errors: KeyedData<InputeError>};
type InputeError =  string | InputeErrorType;
type FormeHistoryItem = {istage: number, data: KeyedData<any>, custom: KeyedData<any>, uidata: KeyedData<any>, focusRefIndex?: number};
type Status = {type: StatusType, message?: string};

// todo: add render for StageType.Begin
interface FormeProps {
  style?: StyleProp<ViewStyle>;
  title?: string;
  fontSize: number;
  initial: KeyedData<any>;
  renderHeader?: (title?: string) => JSX.Element;
  renderEnd: (data: KeyedData<any>, custom: KeyedData<any>) => JSX.Element;
  labelEnd: string;

  // parameter only contains data collected in stages' onData
  onSubmit: (data: KeyedData<any>) => void;
}

interface FormStates {
  // stages (gets from props.children)
  stages: React.ReactElement<StageProps>[];
  childrenData: {refs?: RefChild[]};

  status: Status;

  formeHistory: FormeHistory;

  // holds the errors for the current Stage only.
  // must be re-computed everytime 'next or submit' button is pressed
  errors: KeyedData<InputeError>;
}

enum StageType {
  Begin,
  Child,
  End,
}

enum StageInfo {
  None = 0,
  Renderer = 1 << 0,
  Last = 1 << 1,
  First = 1 << 2,
}

enum StatusType {
  Normal,
  Waiting,
  End,
  Error,
}

enum InputeErrorType {
  Valid = 0,
  MissingField,
}

// todo: all user functions that gets data object should get a copy, not actual object.
export default class Forme extends React.Component<FormeProps, FormStates> {
  static defaultProps = {
    fontSize: 12,
    initial: {},
    renderEnd: () => (
      <View>
        <T>End.</T>
      </View>
    ),
    labelEnd: 'restart',
  };

  constructor(props: PropsWithChildren<FormeProps>) {
    super(props);

    this.state = {
      stages: [],
      childrenData: {},
      formeHistory: new FormeHistory({istage: -1, data: {}, custom: props.initial, uidata: {}}),
      status: {type: StatusType.Normal},
      errors: {},
    };
  }

  static getDerivedStateFromProps(props: PropsWithChildren<FormeProps>) {
    return {
      stages: React.Children.toArray(props.children) as React.ReactElement<StageProps>[],
    };
  }

  public focus() {
    this.state.formeHistory.EnsureFocusSet(this.state.childrenData.refs);
  }

  componentDidMount() {
    const formeHistory = this.state.formeHistory;
    formeHistory.UpdateInitialStage(this._getIstageNext());
    this.setState({formeHistory});
    this.focus();
  }

  componentDidUpdate() {
    this.focus();
  }

  private static jsxButton = (params: {label: string, onPress: ()=>void}): JSX.Element => (
    <TouchableOpacity onPress={params.onPress} style={{marginLeft: 10}}>
      <View style={{marginTop: 8, padding: 4, width: 100, backgroundColor: '#CCC', borderWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
        <T style={{fontSize: 18}}>{params.label}</T>
      </View>
    </TouchableOpacity>
  );

  _getStage = (istage: number): StageData => {
    const {stages, formeHistory} = this.state;
    const res: StageData = {
      type: StageType.Child,
      info: StageInfo.None,
      props: {},
      children: null,
    };

    if (istage === -1) {
      res.type = StageType.Begin;
      return res;
    }

    if (istage === stages.length) {
      res.type = StageType.End;
      return res;
    }

    if (istage >= 0 && istage < stages.length) {
      const stage = stages[istage];
      res.props = stage.props;
      if (res.props.renderContent && this._conditionMet(res.props.conditional))
        res.info |= StageInfo.Renderer;

      if (res.props.isLast || istage + 1 === stages.length)
        res.info |= StageInfo.Last;

      if (istage === formeHistory.IstageBegin)
        res.info |= StageInfo.First;

      res.children = res.props.children;
    }

    return res;
  }

  // todo handle StageType.Begin here
  _needPrevButton = (): boolean => this.state.formeHistory.Cur().istage > 0;
  _conditionMet = (condition?: string) => !!this._getConditionalData(condition);
  _getConditionalData = (condition?: string) => condition ? this.state.formeHistory.Cur().custom[condition] : undefined;

  /*
    1. Check if all inputs are valid.
    2. IF valid, run onData
    3. if last stage, run onSubmit
    4. go to next stage.
  */
  _onNext = (stage: StageData) => {
    this.setState({status: {type: StatusType.Waiting, message: 'Validating Inputs....'}});

    this._checkStage(stage).then(
      validity => {
        if (validity.valid) {
          const message = stage.props.waitMessage ? stage.props.waitMessage(this.state.formeHistory.Cur().data) : 'Processing Data....';
          this.setState({status: {type: StatusType.Waiting, message}});

          // run like this s.t. setState can go through first.
          (async (
            {formeHistory},
            {onSubmit},
            {onData, conditional}) => {
              const {data, custom, istage} = formeHistory.Cur();

              // Must push current state s.t. we can start new data for next stage
              // this is because data from onData does not belong to this stage's states but next stage's.
              formeHistory.Push();

              if (onData) {
                const promise = onData(data, this._getConditionalData(conditional));
                if (promise) {
                  const isSuccessful: boolean = await (promise
                    .then(chg => formeHistory.RecordCustomChange(chg))
                    .then(() => true)
                    .catch(e => {
                      console.error(e.stack);
                      this._onPrev({type: StatusType.Error, message: `Error processing data for stage ${istage}.\n${e.name}: ${e.message}`});
                      return false;
                    })
                  ); 

                  // if we caught error, we will get here.
                  if (!isSuccessful)
                    return;
                }
              }

              const isLastStage = FIn(stage.info, StageInfo.Last);
              // if last element, call submit
              if (isLastStage)
                onSubmit(custom);

              // Done processing everything.
              formeHistory.RecordStageChange(this._getIstageNext());
              this.setState({formeHistory, status: {type: isLastStage ? StatusType.End : StatusType.Normal}});
          })(this.state, this.props, stage.props);
        } else {
          this.setState({status: {type: StatusType.Normal}, errors: validity.errors});
        }
      }
    );
  }

  // Noop since whatever is in history should be already Valid.
  _onPrev = (status: Status = {type: StatusType.Normal}) => {
    const formeHistory = this.state.formeHistory;
    formeHistory.Pop();
    this.setState({formeHistory, status});
  }

  _getIstageNext = () => {
    const {formeHistory, stages} = this.state;
    const {istage, custom} = formeHistory.Cur();

    // if the current isLast, istageNext is the end.
    let istageNext = istage !== -1 && stages[istage].props.isLast ? stages.length : istage + 1;
    while (istageNext < stages.length) {
      const stagePropsNext = stages[istageNext].props;
      if (!stagePropsNext.conditional || (!!custom[stagePropsNext.conditional] && (!!stagePropsNext.renderContent || 'redirect' in stagePropsNext)))
        break;

      istageNext++;
    }

    return istageNext;
  }

  _hasError = (k: string) => this.state.errors[k] !== InputeErrorType.Valid;

  _getErrorMsg: {[k in InputeErrorType]: string} = {
    [InputeErrorType.Valid]: '',
    [InputeErrorType.MissingField]: '*입력 필',
  }

  // 1. Check if required fields have entries.
  // 2. run validators for each field
  // Empty Errors mean that there is no error.
  _checkStage = async ({type, children}: StageData): Promise<Validity> => {
    const res: Validity = {
      valid: true,
      errors: this.state.errors,
    };

    if (type === StageType.Child) {
      const inputeElements = React.Children.toArray(children);
      for (let i = 0; i < inputeElements.length; i++) {
        const inputeChild = inputeElements[i];
        // isValidElement<T> doesn't check the type given by T, therefore we must explicitly check the type.
        if (React.isValidElement<InputeProps>(inputeChild) && inputeChild.type === Inpute) {
          const k = GetNameFormeChild(inputeChild.props);
          const v = this.state.formeHistory.Cur().data[k];

          if (inputeChild.props.required && !v) {
            res.valid = false;
            res.errors[k] = InputeErrorType.MissingField;
            continue;
          }

          const errorMsg = await InputeUtil._getValidator(inputeChild.props)(v);
          if (errorMsg !== true) {
            res.valid = false;
            res.errors[k] = errorMsg;
            continue;
          }

          res.errors[k] = InputeErrorType.Valid;
        }
      }
    }

    return res;
  }

  // Set up s.t. hitting enter/tab on inputs causes the next input to be focused
  // with an exception: (if input takes multiple line, hitting enter changes to new line).
  // Also, enter on last input causes onSubmit to be fired.
  // where as tab on last input causes the next/submit button to be selected.
  _processChildren = (contents: React.ReactNode, stage: StageData) => {
    const children = React.Children.toArray(contents);
    const processed: React.ReactNode[] = new Array(children.length);
    const refs: RefChild[] = new Array(children.length);

    let inputeProps: Partial<InputeProps> = {
      onEnter: () => this._onNext(stage),
      // onTab: () => {},
    }

    for (let i = children.length - 1; i >= 0; i--) {
      const childCur = children[i];
      const {ref, child} = this._processChild(i, childCur, inputeProps);
      refs[i] = ref;
      processed[i] = child;

      if (React.isValidElement<InputeProps>(childCur) && childCur.type === Inpute)
        inputeProps = {};
    }

    // do this instead of calling setState
    const childrenData = this.state.childrenData;
    childrenData.refs = refs;

    return processed;
  }

  _processChild = (i: number, child: React.ReactNode, inputePropsEnter: Partial<InputeProps>): {ref?: RefChild, child: React.ReactNode} => {
    if (React.isValidElement<FormeChildProps<any>>(child)) {
      const {formeHistory, errors} = this.state;

      // has a side effect depending on the value of input (if there is any input).
      const {k, defaultValue} = formeHistory.GetDefaultValue(child.props);
      const ref = React.createRef();

      if (child.type === Inpute) {
        const error = errors[k];
        const inputePropsSet: InputeProps = child.props as InputeProps;

        const numberOfLines: number = inputePropsSet.numberOfLines !== undefined ? inputePropsSet.numberOfLines : inputePropsSet.multiline ? 4 : 1;
        const multiline: boolean = numberOfLines > 1;

        const inputeProps: Partial<InputeProps> = {
          ref,
          defaultValue,
          errorMsg: typeof error === 'string' ? error : this._getErrorMsg[error],
          onChangeText : (text: string) =>  {
            /* eslint-disable react/no-direct-mutation-state */
            formeHistory.RecordUIChange({i, ref}, k, text);
    
            // remove highlight if there is any text entered
            // must re-compute hasError in here (don't use captured value)
            if (this._hasError(k) && text) {
              errors[k] = InputeErrorType.Valid;
              this.setState({errors});
            }
          },
          fontSize: inputePropsSet.fontSize ? inputePropsSet.fontSize : this.props.fontSize, // Try using child's fontSize. If not default to Form's fontSize.
          multiline,
          numberOfLines,
        };

        if (!multiline)
          Object.assign(inputeProps, inputePropsEnter);

        return {ref, child: React.cloneElement(child, inputeProps)};
      } else if (child.type === Radioe) {
        const radioeProps: Partial<RadioeProps> = {
          defaultValue,
          onValueChange: (itemValue: any) => {
            // I'm updating visual when value changes, therefore I must setState.
            if (formeHistory.RecordUIChange({i, ref}, k, `${itemValue}`))
              this.setState({formeHistory});
          }
        }
        return {ref, child: React.cloneElement(child, radioeProps)};
      } else if (child.type === Liste) {
        const listePropsSet: ListeProps<any> = child.props as ListeProps<any>;
        const {input, data, renderItem} = listePropsSet;
        if (data)
          throw new Error("Data must be passed through an 'input' attribute");

        // note that if the input source changes, defaultValue becomes undefined
        // therefore, it's sufficient to just take the defaultValue
        // if there isn't one, get the initial selectionState from input
        const selectionState: boolean[] = defaultValue ? defaultValue : input ? input.map(() => false) : [];

        // ensure that we have the right reference to the selectionState stored in our history.
        formeHistory.RecordUIChangeWithInput({i, ref}, k, selectionState);

        const listeProps: Partial<ListeProps<any>> = {
          data: input,
          input: undefined, // Liste should only see data in props.
          renderItem: (item, index) => {
            return (
              <Selectable
                defaultValue={defaultValue ? defaultValue[index] : undefined}
                onChange={selected => {
                  // doesn't need to setState since Selectable handles the change in visual.
                  selectionState[index] = selected;
                }}>
                {renderItem(item, index)}
              </Selectable>
            )
          },
        };

        return {ref, child: React.cloneElement(child, listeProps)};
      }
    }

    // if we don't need new props, just keep the child.
    return {ref: undefined, child};
  }

  _reset = () => {
    this.state.formeHistory.reset();
    this.setState({errors: {}});
  }

  // we do not need to re-render anything when data changes
  // therefore mutate state directly instead of calling this.setState.
  _renderButton = (stage: StageData): JSX.Element | null => {
    if ('redirect' in stage.props) {
      const redirect = (stage.props as StageRedirectProps).redirect;
      return redirect === null ? null : Forme.jsxButton(redirect);
    }
    
    // Assume that begin will never be the end. (also note that istage === -1 is Begin).
    if (stage.type === StageType.Begin)
      return Forme.jsxButton({label: 'Next', onPress: () => this._onNext(stage)});

    if (stage.type === StageType.End)
      return Forme.jsxButton({label: this.props.labelEnd, onPress: () => this._reset()});

    const isFirst = FIn(stage.info, StageInfo.First);
    const isLast = FIn(stage.info, StageInfo.Last);
    return (
      <Fragment>
        {isFirst ? null : Forme.jsxButton({label: 'Prev', onPress: () => this._onPrev()})}
        {Forme.jsxButton({label: isLast ? 'submit' : 'Next', onPress: () => this._onNext(stage)})}
      </Fragment>
    );
  }

  _renderStageTitle = ({getTitle}: StageProps): JSX.Element | null => getTitle
    ? (
      <T style={{fontSize: 18, fontWeight: 'bold'}}>{getTitle(this.state.formeHistory.Cur().data)}</T>
    )
    : null;

  _renderMap: {[k in StageType]: (stage: StageData) => React.ReactNode} = {
    [StageType.Begin]: () => (<View></View>),
    [StageType.Child]: (stage) => {
      if (FIn(stage.info, StageInfo.Renderer)) {
        const {conditional, renderContent} = stage.props;
        if (renderContent) {
          const {data} = this.state.formeHistory.Cur();
          const contents = renderContent(data, this._getConditionalData(conditional));
          return this._processChildren(contents, stage);
        }
        
        // throw otherwise, we should only get here if conditional and renderContent are valid.
        return (<View><T>{'Unexpected Error'}</T></View>);
      } else {
        return this._processChildren(stage.children, stage);
      }
    },
    [StageType.End]: () => {
      try {
        return this.props.renderEnd(this.state.formeHistory.Cur().data, this.state.formeHistory.Cur().custom);
      }
      catch {
        return (
          <View>
            <T>No Data</T>
          </View>
        );
      }
    },
  }

  // todo: show message
  _renderStatus: {[k in StatusType]: (message?: string) => React.ReactNode} = {
    [StatusType.Normal]: () => null,
    [StatusType.Waiting]: (message) => (
      <View style={styles.Modal}>
        {message ? (<T style={styles.ModalMessage}>{message}</T>) : null}
        <ActivityIndicator size={'large'} color={'white'} />
      </View>
    ),
    [StatusType.End]: (message) => message
      ? (<TouchableOpacity style={styles.Modal} onPress={() => this.setState({status: {type: StatusType.Normal}})}>
        <T style={styles.ModalMessage}>{message}</T>
      </TouchableOpacity>)
      : null,
    [StatusType.Error]: (message) => (
      <TouchableOpacity style={styles.Modal} onPress={() => this.setState({status: {type: StatusType.Normal}})}>
        <T style={[styles.ModalMessage, {color: 'salmon'}]}>{message ? message : 'UnknownError'}</T>
      </TouchableOpacity>
    ),
  }

  render() {
    const {renderHeader, title, style} = this.props;
    const {status, formeHistory} = this.state;
    const istage = formeHistory.Cur().istage;
    const stage = this._getStage(istage);

    return (
      <View style={style}>
        {renderHeader
          ? renderHeader(title)
          : title
            ? (<T>{title}</T>)
            : null}
        {this._renderStageTitle(stage.props)}
        <CompOnce key={istage} onLoad={stage.props.onLoad}>
          {this._renderMap[stage.type](stage)}
        </CompOnce>
        <View style={{flexDirection:'row', alignItems: 'center', alignSelf: 'flex-end'}}>
          {this._renderButton(stage)}
        </View>
        {this._renderStatus[status.type](status.message)}
      </View>
    );
  }
}

// todo: figure out exactly how input should be stored in formeHistory
// especially if there are more than one child with input.
// used only if the FormeChild has an input data.
type FormeChildPropsWithInput<I, T> = {input?: Readonly<I>} & FormeChildProps<T>;

type FormeChildProps<T> = {
  label: string,
  name?: string,
  defaultValue?: T,
} & React.RefAttributes<any>;

// name to identify the inpute with. If absent, use label as the name.
const GetNameFormeChild = (props: FormeChildProps<any>) => props.name ? props.name : props.label;

interface InputeProps extends TextInputProps, FormeChildProps<string> {
  // label: string;
  // name?: string;
  required?: boolean;
  fontSize?: number;
  // Validator checks if this input has valid data.
  validator?: Validator;

  // ** props below this are set by Forme.
  // todo: re-do this since there might be more than one input.
  onEnter?: () => void;

  // indicate that there is an error with this Input.
  errorMsg?: string;
  // defaultValue?: string; // from TextInputProps.
}


// validator={(word: string) => this.props.remote.GetQres(word).then(d => d && d.length > 0 ? `${word} already exists` : true)}
export const Inpute = React.forwardRef((props: InputeProps, ref: React.Ref<any>): JSX.Element => {
  const {label, name, fontSize, required, errorMsg, validator, onEnter, style, ...rest} = props;
  const _fontSize = fontSize ? fontSize : 12;

  const errorObj: {borderColor?: string, jsx?: JSX.Element} = {};
  if (errorMsg) {
    errorObj.borderColor = 'salmon';
    errorObj.jsx = (
      <View style={{position: 'absolute', right: 10, top: 0, height: '100%', alignItems: 'center', justifyContent: 'center'}}>
        <T style={{color: 'red', fontWeight: 'bold'}}>{errorMsg}</T>
      </View>
    );
  }

  return (
    <View style={[{flex: 1, flexDirection: 'row-reverse', marginTop: 4}]}>
      <TI
        ref={ref}
        style={[{flex: 1, borderColor: errorObj.borderColor, borderWidth: 1, fontSize: _fontSize}, style]}
        onKeyPress={onEnter
          ? ({nativeEvent}) => {
              if (nativeEvent.key === 'Enter')
                onEnter();
            }
          : () => {}}
        {...rest} />
      <T style={{fontSize: _fontSize, paddingLeft: 10, paddingRight: 10}}>{label}</T>
      {errorObj.jsx}
    </View>
  );
});

const InputeUtil = {
  _getValidator: (props: InputeProps): Validator => props.validator ? props.validator : () => Promise.resolve(true),
}

// if conditional meets and renderContent exits, contents are generated by running renderContent
// otherwise, props.children is the content.
interface StageProps extends React.Attributes {
  getTitle?: (data: KeyedData<any>) => string;

  // When the user tries to move on to the next Stage,
  // and if everything is valid (e.g. required fields are filled)
  // then onData will run before moving on to the next Stage.
  // Returned value will be stored so that it can be used in renderContent
  // of any Stage including this Stage or any Stage after this.
  onData?: (data: KeyedData<any>, condition: any) => Promise<KeyedData<any>> | void;

  // run when this Stage is rendered.
  onLoad?: () => void;

  // message to display while waiting for onData
  waitMessage?: (data: KeyedData<any>) => string;

  // if an object that can be accessed with conditional exists when this Stage runs
  // this Stage will produce contents by running renderContent.
  // If the object or renderContent doesn't exist, this Stage is skipped.
  conditional?: string;

  // data contains all information set in previous stage through Inpute or Radioe.
  // condition is the object required by 'conditional' field
  // this object is usually set by onData of the previous Stage.
  // renderContent is used only if condition object is available.
  renderContent?: (data: KeyedData<any>, condition: any) => React.ReactNode;

  // indicates that this stage is the last one.
  isLast?: boolean;
}

// Just a wrapper for Form.
export function Stage(props: PropsWithChildren<StageProps>): JSX.Element {
  return (<Fragment>{props.children}</Fragment>);
}

// if conditional meets, this stage will be shown with 'redirect' button.
type StageRedirectProps = StageProps & {conditional: string, redirect: {label: string, onPress: () => void} | null};
export function StageRedirect(props: PropsWithChildren<StageRedirectProps>): JSX.Element {
  return (<Fragment>{props.children}</Fragment>);
}

type RadioeValue = number | string;
export type RadioeData = {title: string, value: RadioeValue};
export type IHaveValue = {value: string};

interface RadioeProps extends FormeChildProps<string> {
  // label: string;
  // name: string;
  labelNoSelection: string;
  data: RadioeData[];
  onValueChange?: (itemValue: RadioeValue) => void;
}

interface RadioeStates {
  selected: RadioeValue;
}

export class Radioe extends React.Component<RadioeProps, RadioeStates> {
  static defaultProps = {
    labelNoSelection: '선택안됨'
  };

  constructor(props: RadioeProps) {
    super(props);

    this.state = {
      selected: -1,
    }
  }

  static getDerivedStateFromProps(props: RadioeProps, state: RadioeStates) {
    return {
      selected: state.selected !== -1
        ? state.selected
        : props.defaultValue ? props.defaultValue : -1,
    }
  }

  componentDidUpdate() {
    // whenever we do a render, we must tell the choice we rendered.
    if (this.props.onValueChange)
      this.props.onValueChange(this.state.selected);
  }

  render() {
    const {label, data, labelNoSelection} = this.props;
    return (
      <View>
        <T>{label}</T>
        <Picker
          selectedValue={this.state.selected}
          onValueChange={(itemValue) => this.setState({selected: itemValue})}
          itemStyle={{width: '100%'}}
        >
          <Picker.Item key={'noselection'} label={labelNoSelection} value={-1} />
          {data.map(({title, value}) => (<Picker.Item key={`${label}-${value}`} label={title} value={value} />))}
        </Picker>
      </View>
    );
    // return (
    //   <FlatList 
    //     data={this.props.data}
    //     renderItem={({item}) => (
    //       <View style={{flexDirection: 'row', paddingVertical: 4, paddingHorizontal: 10}}>
    //         <T>{item}</T>
    //       </View>
    //     )}
    //     keyExtractor={str => str}
    //   />
    // );
  }

  public static GetData(obj: KeyedData<IHaveValue>): RadioeData[] {
    return Object.keys(obj).map(k => {
      return {
        title: obj[k].value,
        value: k
      };
    });
  }
}

type ListeProps<T> = {} & ResListProps<T> & FormeChildPropsWithInput<ResData<T>, boolean[]>;

export function Liste<T>(props: ListeProps<T>) {
  const {input, data, ...rest} = props;
  if (input)
    throw new Error("Input props must be handled by the parent Forme");
  return (<ResList data={data} {...rest} />);
}

type CompOnceProps = React.Attributes & {onLoad?: () => void};
class CompOnce extends React.PureComponent<CompOnceProps, any> {
  constructor(props: CompOnceProps) {
    super(props);
    if (props.onLoad)
        props.onLoad();
  }

  render() {
    return (<View>{this.props.children}</View>);
  }
}

class FormeHistory {
  private history: FormeHistoryItem[];
  private curChange: FormeHistoryItem;

  private initial: FormeHistoryItem;

  constructor(initial: FormeHistoryItem) {
    this.history = [];
    this.curChange = FormeHistory.clone(initial);
    this.initial = initial;
  }

  public get IstageBegin() {
    return this.initial.istage;
  }

  public Cur(): Readonly<FormeHistoryItem> {
    return this.curChange;
  }

  public GetDefaultValue<TInput, TValue>(props: FormeChildProps<any> | FormeChildPropsWithInput<TInput, TValue>): {k: string, defaultValue: TValue | undefined} {
    const k = GetNameFormeChild(props);
    let defaultValue = this.curChange.uidata[k];

    if ('input' in props) {
      this._ensureData(k);

      // make sure that we don't override the existing data.
      const input: TInput | undefined = props.input;
      const curData: Partial<FormeDataWithInput<TInput, TValue>> = this.curChange.data[k];

      // if input has changed, we must not reuse old data (i.e. don't use defaultValue).
      if (input && input !== curData.input) {
        curData.input = input;

        // input itself is readonly, so no need to reset the input.
        // this resets the defaultValue that might have been acquired from previous states
        this.curChange.uidata[k].value = undefined;
      }

      defaultValue = this.curChange.uidata[k].value;
    }

    return {k, defaultValue};
  }

  public UpdateInitialStage(istage: number) {
    if (this.history.length === 0) {
      this.initial.istage = istage;
      this.curChange.istage = istage;
    }
  }

  public RecordUIChangeWithInput<TValue>(focusObj: FocusObj, name: string, value: TValue) {
    if (!this.curChange.data || !this.curChange.data[name]) {
      throw new Error("GetDefaultValue must have created an object");
    }

    const dataWithInput: FormeDataWithInput<any, TValue> = this.curChange.data[name];
    dataWithInput.value = value;

    // this won't reset uidata/data, because of shallow comparison but that's fine.
    this.RecordUIChange(focusObj, name, dataWithInput);
  }

  public RecordUIChange<T>(focusObj: FocusObj, name: string, obj: T): boolean {
    // child that is being interacted with will gain focus.
    this._setFocus(focusObj);

    this._ensureData();

    // shallow comparison.
    if (this.curChange.uidata[name] === obj)
      return false;

    this.curChange.uidata[name] = obj;
    this.curChange.data[name] = obj;
    return true;
  }

  public RecordStageChange(istageNext: number) {
    this.curChange.istage = istageNext;

    if (_debug_formeHistory)
      console.log('New stage number: ', istageNext);
  }

  public RecordCustomChange(obj: KeyedData<any>) {
    Object.assign(this.curChange.custom, obj);
  }

  public EnsureFocusSet(refs: undefined | RefChild[]) {
    if (!refs)
      return;

    // set the first child as the focused item if none is set.
    // focus can change by interacting with the children.
    const curFocus = this.curChange.focusRefIndex !== undefined ? refs[this.curChange.focusRefIndex] : undefined;
    const focusObj: FocusObj = {i: -1, ref: undefined};
    if (curFocus && curFocus.current) {
      focusObj.i = this.curChange.focusRefIndex as number;
      focusObj.ref = curFocus;
    } else {
      focusObj.ref = refs.find((ref, i) => {
        focusObj.i = i;
        return ref !== undefined && ref.current;
      });
    }
    this._setFocus(focusObj);
  }

  public Push() {
    this.history.push(FormeHistory.clone(this.curChange));

    if (_debug_formeHistory) {
      console.log('Pushing an HistoryItem');
      FormeHistory._logHistoryItem(this.curChange);
    }
  }

  public Pop() {
    const prev = this.history.pop();
    if (prev) {
      this.curChange = prev;

      if (_debug_formeHistory) {
        console.log('Poping an HistoryItem');
        FormeHistory._logHistoryItem(this.curChange);
      }
    }
  }

  public reset() {
    this.history = [];
    this.curChange = FormeHistory.clone(this.initial);
  }

  private _setFocus({i, ref}: FocusObj) {
    this.curChange.focusRefIndex = i;
    if (ref && ref.current && 'focus' in ref.current) {
      ref.current.focus();
    }
  }

  private _ensureData(name?: string) {
    if (!this.curChange.data)
      this.curChange.data = {};
    
    if (!this.curChange.uidata)
      this.curChange.uidata = {};

    if (name) {
      if (!this.curChange.uidata[name])
        this.curChange.uidata[name] = {};

      if (!this.curChange.data[name])
        this.curChange.data[name] = {};
    }
  }

  private static merge(to: KeyedData<any>, from: KeyedData<any>) {
    Object.keys(from).forEach(k => {
      if (typeof from[k] === 'object')
        to[k] = Object.assign(to[k] ? to[k] : {}, from[k]);
      else
        to[k] = from[k];
    });
    return to;
  }

  private static clone(item: KeyedData<any>): FormeHistoryItem {
    return FormeHistory.merge({}, item) as FormeHistoryItem;
  }

  private static _logHistoryItem = (item: FormeHistoryItem) => {
    console.log('istage: ', item.istage);
    console.log('data: ', JSON.stringify(item.data));
    console.log('custom: ', JSON.stringify(item.custom));
  }
}

const styles = StyleSheet.create({
  Modal: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems:'center'},
  ModalMessage: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#FFF',
  }
});