import React, {Fragment} from 'react';
import {ActivityIndicator, FlatList, View, StyleSheet, StyleProp, ViewStyle, TouchableOpacity, TextInput, NativeSyntheticEvent, TextInputKeyPressEventData} from 'react-native';
import {T, TI, THighlightedPrefix} from './T';
import Remote, {HintItem, HintManager} from '../lib/remote';
import Highlight from '../lib/highlight';

interface SBProps {
  remote: Remote,
  query: string,
  onSearch: (q: string) => void,
  height: number,
  style?: StyleProp<ViewStyle>,
}

interface SBState {
  query: string, // current query value.
  prevPropQuery: string,
  hintBox: HintBox,
  footMsg: string,
}

export default class SearchBox extends React.Component<SBProps, SBState> {
  private _refTI: React.RefObject<TextInput>;

  constructor(props : SBProps) {
    super(props);

    this._refTI = React.createRef();

    // must keep same object for the data that goes into FlatList
    this.state = {
      query: props.query,
      prevPropQuery: props.query,
      hintBox: new HintBox(), // singleton
      footMsg: "",
    }
  }

  static getDerivedStateFromProps(props: SBProps, state: SBState) {
    if (state.prevPropQuery !== props.query) {
      const derivedState: Pick<SBState, "query" | "prevPropQuery"> = {
        query: props.query,
        prevPropQuery: props.query,
      };
      return derivedState;
    }

    return null;
  }

  // This gets called when FlatList is rendered for the first time.
  _onChangeText = (q: string) => {
    this._updateQuery(q);
    this._loadHints(() => this.props.remote.GetQhint(q.toLocaleLowerCase()));
  }

  _updateQuery = (query: string) => {
    this.setState({query});
  }

  _getQhintMore = (q: string) => {
    this._loadHints(() => this.props.remote.GetQhintMore(q).then(hintResult => hintResult.hasChange ? hintResult.data : null));
  }

  _doSearch = (q : string) => {
    this._updateQuery(q);
    this.setState({hintBox: this.state.hintBox.Reset()});
    this.props.onSearch(q);
  }

  _loadHints = (loader: () => Promise<HintItem[] | null>) => {
    this.state.hintBox.StartLoading(loader).then(hintBox => this.setState({hintBox}));
  }
  
  _removeHintBox = () => {
    this.setState({hintBox: this.state.hintBox.Reset()});
  }

  // need to import: TextInputKeyPressEventData, NativeSyntheticEvent
  _onKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    const {key} = e.nativeEvent;
    // debug
    // this.setState({footMsg: key});

    if (key === 'Tab') {
      e.preventDefault();
      const hintItemFirst = this.state.hintBox.PeekFirst();
      if (hintItemFirst) {
        this._onChangeText(hintItemFirst.actual ? hintItemFirst.actual : hintItemFirst.id);
      }
    }

    // if (key === 'ArrowDown' || key === 'ArrowUp')
    // {
    //   e.preventDefault();

    //   if (key === 'ArrowDown')
    //     this.state.hintBox.SelectNext();
    //   else
    //     this.state.hintBox.SelectPrev();
    // }
  }

  // todo:
  // probably coalesce same words in hint; or maybe don't and show snippets.
  // highlight searched part.
  _renderHintItem = ({item, index} : {item: HintItem, index: number}) => {
    if (item === HintManager.PreLoading)
      return (<ActivityIndicator size={'small'} />);

    const word = item.actual ? item.actual : item.id;
    return (<TouchableOpacity
      style={this.state.hintBox.GetStyle(index)}
      onPress={() => this._doSearch(word)}>
      <View style={styles.HintItem}>
        <THighlightedPrefix style={styles.FontSearchHint} value={word} prefix={this.state.query} />
      </View>
    </TouchableOpacity>); 
  }

  _renderFooter = () => {
    if (this.state.footMsg)
      return (<T>{this.state.footMsg}</T>);
    else
      return null;
  }

  _renderHintBox = (hintBox : HintBox, stDebug: string) => {
    if (hintBox.items.length === 0)
      return null;
    
    return (
      <Fragment>
        <View style={styles.SearchHintSeparator}></View>
        <T>{stDebug}</T>
        <FlatList
          style={styles.SearchHint}
          data={hintBox.items}
          renderItem={this._renderHintItem}
          keyExtractor={item => item.id}
          onEndReached={() => this._getQhintMore(this.state.query)}
        />
      </Fragment>
    );
  }

  render() {
    return (
      <View style={this.props.style}>
        <View style={styles.SearchBox}>
          <TI
            ref={this._refTI}
            style={[{height: this.props.height}, styles.FontSearchBox]}
            value={this.state.query}
            onChangeText={this._onChangeText}
            onEndEditing={this._removeHintBox}
            onBlur={this._removeHintBox} // TODO: differentiates blur vs. losing focus on the browser.
            onSubmitEditing={({nativeEvent}) => this._doSearch(nativeEvent.text)}
            onFocus={({nativeEvent}) => this._onChangeText(nativeEvent.text)}
            onKeyPress={this._onKeyPress}
          />
          {this._renderHintBox(this.state.hintBox, '')}
        </View>
      </View>
    );
  }
}

class HintBox {
  public items: HintItem[];
  public highlight: Highlight;

  private mode: 'active' | 'inactive';

  constructor() {
    this.items = [];
    this.highlight = new Highlight();
    this.mode = 'inactive';
  }

  public PeekFirst = () => this.items && this.items.length > 0 ? this.items[0] : null;

  StartLoading(loader: () => Promise<HintItem[] | null>): Promise<HintBox> {
    this.mode = 'active';

    return loader().then(
      hintItems => {
        if (this.mode === 'active' && hintItems !== null)
          this.Set(hintItems);

        return this;
      }
    );
  }

  private Set(items: HintItem[]) : void {
    this.items = items;
    this.highlight.RemoveSelection();
  }

  Reset(): HintBox {
    this.Set([]);
    this.mode = 'inactive';
    return this;
  }

  SelectNext() {
    this.highlight.SelectNext(this.items);
  }

  SelectPrev() {
    this.highlight.SelectPrev(this.items);
  }

  GetStyle(i: number) {
    if (this.highlight.IsSelected(i))
      return {backgroundColor: '#CCC'};
    else
      return null;
  }
}

const styles = StyleSheet.create(
  {
    SearchBox: {
      alignSelf: 'center',
      borderWidth: 2,
      width: '80%',
    },
    SearchHint: {
      maxHeight: 300,
      padding: 10,
      backgroundColor: 'white'
    },
    SearchHintSeparator: {
      alignSelf: 'center',
      width: '100%',
      height: '1px',
      backgroundColor: '#CCC'
    },
    FontSearchBox: {
      fontSize: 36,
      paddingHorizontal: 10,
    },
    FontSearchHint: {
      fontSize: 27,
      color: '#888',
    },  
    HintItem: {
      paddingHorizontal: 10,
    },
  }
);