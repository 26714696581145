import React, {Fragment} from 'react';
import { ViewStyle, StyleProp, ActivityIndicator, FlatList, View } from "react-native";
import {GetScrollbarSize} from '../lib/commons';

export interface ResListProps<T> {
  data?: Readonly<ResData<T>>;
  style?: StyleProp<ViewStyle>;
  onGetMore?: () => void;
  renderOnNull?: () => JSX.Element | null;
  renderFooter?: () => JSX.Element;
  renderItem: (item: Readonly<T>, index: number) => JSX.Element;
  ItemSeparatorComponent?: () => JSX.Element;
  keyExtractor?: (item: Readonly<T>, index: number) => string;
  overflowY?: 'hidden' | 'scroll';
}

export type ResData<T> = undefined | null | T[];

// TODO: add hyperlink to the actual word.
export function ResList<T>(props: ResListProps<T>): JSX.Element | null {
  if (props.data === null && props.renderOnNull) {
    const jsxNull = props.renderOnNull();
    return jsxNull === null ? null : (<View style={props.style}>{jsxNull}</View>);
  }

  const scrollbarSize = GetScrollbarSize();
  const {data, overflowY, style, onGetMore, keyExtractor, ItemSeparatorComponent, renderItem, renderFooter} = props;
  return (
    <View style={[{justifyContent: 'center', width: `calc(100% + ${scrollbarSize+1}px)`}, style]}>
      {
        _res(
          data,
          data => (
            <Fragment>
              <FlatList
                style={{overflowY} as StyleProp<ViewStyle>}
                data={data}
                onEndReached={onGetMore ? onGetMore : () => {}}
                keyExtractor={keyExtractor ? keyExtractor : _keyExtractor} // todo: this assumes IWord
                ItemSeparatorComponent={ItemSeparatorComponent ? ItemSeparatorComponent : undefined}
                renderItem={({item, index}) => renderItem(item, index)}
              />
              {renderFooter ? renderFooter() : null}
            </Fragment>))
      }
    </View>
  );
}

function _res<T>(data: Readonly<ResData<T>>, render: (data: Readonly<T[]>) => JSX.Element) {
  if (!data)
    return null;

  if (data.length === 0)
    return (<ActivityIndicator size={'large'} />);

  return render(data);
}

type ResItemP<T> = {load: () => Promise<T>, style?: StyleProp<ViewStyle>, render: (data: T, style?: StyleProp<ViewStyle>) => JSX.Element};
type ResItemS<T> = {data: ResData<T>};

export class ResItem<T> extends React.Component<ResItemP<T>, ResItemS<T>> {
  static defaultProps = {
    param: undefined,
  }

  constructor(props: ResItemP<T>) {
    super(props);

    this.state = {
      data: []
    }

    _populate(
      this.state.data,
      data => this.setState({data}),
      () => this.props.load().then(res => res ? [res] : []));
  }

  render() {
    return (
      <View style={this.props.style}>
        {_res(this.state.data, data => this.props.render(data[0]))}
      </View>
    )
  }
}

// This will only run populate if data passed in is undefined.
ResList.populate = _populate;
ResList.isNonEmpty = (data: any): boolean => data && data.length > 0;

function _populate<T>(data: ResData<T>, onStateChange: (data: ResData<T>) => void, getter: () => Promise<T[]>) {
  if (data === null)
    return;

  // if we are loading data for the first time, set data to [] s.t. ActivityIndicator is shown
  // if we have an existing, data we don't need to show ActivityIndicator, data will just change as we receive.
  if (data === undefined) {
    data = [];
    onStateChange(data);
  }

  getter().then(listNew => onStateChange(listNew.length > 0 ? listNew : null));
}

const _keyExtractor = (item: any) => `${item.word}-${item.n}-${item.id}`;