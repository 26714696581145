import {Test, TestCase} from './tests';
import Korean from '../lib/korean';

/*
  bug:
    a. consonant range isn't exactly correct;
      ㄱ: [ㄱ, ㄲ], but [ㄱ, ㄴ]
      ㄺ: [ㄹㄱ, ㄹㄲ], but [ㄹㄱ, ㄹㄴ]
    b. Special cased:
      ㅂ: [ㅂ, ㅅ]
      ㄼ: [ㄹㅂ, ㄹㅅ]
*/

export default class TestKorean implements Test {
  private static chLast = String.fromCharCode('힣'.charCodeAt(0)+1);

  getTestCases() : TestCase[] {
    return [
      {
        input: '가',
        expected: [['가', '개']],
      },
      {
        input: '각', // 가ㄱ
        expected: [['각', '간'], ['가가', '가까']],
      },
      {
        input: '갂', // 가ㄲ
        expected: [['갂', '갃'], ['가까', '가나']],
      },
      {
        input: '갃', // 각ㅅ
        expected: [['갃', '간'], ['각사', '각싸']],
      },
      {
        input: '간', // 가ㄴ
        expected: [['간', '갇'], ['가나', '가다']],
      },
      {
        input: '갅', // 간ㅈ
        expected: [['갅', '갆'], ['간자', '간짜']],
      },
      {
        input: '갆', // 간ㅎ
        expected: [['갆', '갇'], ['간하', `간${TestKorean.chLast}`]],
      },
      {
        input: '갇', // 가ㄷ
        expected: [['갇', '갈'], ['가다', '가따']],
      },
      {
        input: '갈', // 가ㄹ
        expected: [['갈', '감'], ['가라', '가마']],
      },
      {
        input: '갉', // 갈ㄱ
        expected: [['갉', '갊'], ['갈가', '갈까']],
      },
      {
        input: '갊', // 갈ㅁ
        expected: [['갊', '갋'], ['갈마', '갈바']],
      },
      {
        input: '갋', // 갈ㅂ
        expected: [['갋', '갌'], ['갈바', '갈빠']],
      },
      {
        input: '갌', // 갈ㅅ
        expected: [['갌', '갍'], ['갈사', '갈싸']],
      },
      {
        input: '갍', // 갈ㅌ
        expected: [['갍', '갎'], ['갈타', '갈파']],
      },
      {
        input: '갎', // 갈ㅍ
        expected: [['갎', '갏'], ['갈파', '갈하']],
      },
      {
        input: '갏', // 갈ㅎ
        expected: [['갏', '감'], ['갈하', `갈${TestKorean.chLast}`]],
      },
      {
        input: '감', // 가ㅁ
        expected: [['감', '갑'], ['가마', '가바']],
      },
      {
        input: '갑', // 가ㅂ
        expected: [['갑', '갓'], ['가바', '가빠']],
      },
      {
        input: '값', // 갑ㅅ
        expected: [['값', '갓'], ['갑사', '갑싸']],
      },
      {
        input: '갓', // 가ㅅ
        expected: [['갓', '갔'], ['가사', '가싸']],
      },
      {
        input: '갔', // 가ㅆ
        expected: [['갔', '강'], ['가싸', '가아']],
      },
      {
        input: '강', // 가ㅇ
        expected: [['강', '갖'], ['가아', '가자']],
      },
      {
        input: '갖', // 가ㅈ
        expected: [['갖', '갗'], ['가자', '가짜']],
      },
      {
        input: '갗', // 가ㅊ
        expected: [['갗', '갘'], ['가차', '가카']],
      },
      {
        input: '갘', // 가ㅋ
        expected: [['갘', '같'], ['가카', '가타']],
      },
      {
        input: '같', // 가ㅌ
        expected: [['같', '갚'], ['가타', '가파']],
      },
      {
        input: '갚', // 가ㅍ
        expected: [['갚', '갛'], ['가파', '가하']],
      },
      {
        input: '갛', // 가ㅎ
        expected: [['갛', '개'], ['가하', `가${TestKorean.chLast}`]],
      },
      {
        input: 'ㄱ',
        expected: [['ㄱ', 'ㄴ'], ['가', '까']], // technically [ㄱ, ㄲ], but not worth fixing
      },
      {
        input: 'ㄲ',
        expected: [['ㄲ', 'ㄳ'], ['까', '나']],
      },
      {
        input: 'ㄳ',
        expected: [['ㄳ', 'ㄴ'], ['ㄱㅅ', 'ㄱㅆ'], ['ㄱ사', 'ㄱ싸']],
      },
      {
        input: 'ㄴ',
        expected: [['ㄴ', 'ㄷ'], ['나', '다']],
      },
      {
        input: 'ㄵ',
        expected: [['ㄵ', 'ㄶ'], ['ㄴㅈ', 'ㄴㅉ'], ['ㄴ자', 'ㄴ짜']],
      },
      {
        input: 'ㄶ',
        expected: [['ㄶ', 'ㄷ'], ['ㄴㅎ', 'ㄴㅏ'], ['ㄴ하', `ㄴ${TestKorean.chLast}`]],
      },
      {
        input: 'ㄷ',
        expected: [['ㄷ', 'ㄸ'], ['다', '따']],
      },
      {
        input: 'ㄸ',
        expected: [['ㄸ', 'ㄹ'], ['따', '라']],
      },
      {
        input: 'ㄹ',
        expected: [['ㄹ', 'ㅁ'], ['라', '마']],
      },
      {
        input: 'ㄺ',
        expected: [['ㄺ', 'ㄻ'], ['ㄹㄱ', 'ㄹㄴ'], ['ㄹ가', 'ㄹ까']],
      },
      {
        input: 'ㄻ',
        expected: [['ㄻ', 'ㄼ'], ['ㄹㅁ', 'ㄹㅂ'], ['ㄹ마', 'ㄹ바']],
      },
      {
        input: 'ㄼ',
        expected: [['ㄼ', 'ㄽ'], ['ㄹㅂ', 'ㄹㅅ'], ['ㄹ바', 'ㄹ빠']],
      },
      {
        input: 'ㄽ',
        expected: [['ㄽ', 'ㄾ'], ['ㄹㅅ', 'ㄹㅆ'], ['ㄹ사', 'ㄹ싸']],
      },
      {
        input: 'ㄾ',
        expected: [['ㄾ', 'ㄿ'], ['ㄹㅌ', 'ㄹㅍ'], ['ㄹ타', 'ㄹ파']],
      },
      {
        input: 'ㄿ',
        expected: [['ㄿ', 'ㅀ'], ['ㄹㅍ', 'ㄹㅎ'], ['ㄹ파', 'ㄹ하']],
      },
      {
        input: 'ㅀ',
        expected: [['ㅀ', 'ㅁ'], ['ㄹㅎ', 'ㄹㅏ'], ['ㄹ하', `ㄹ${TestKorean.chLast}`]],
      },
      {
        input: 'ㅁ',
        expected: [['ㅁ', 'ㅂ'], ['마', '바']],
      },
      {
        input: 'ㅂ',
        expected: [['ㅂ', 'ㅅ'], ['바', '빠']],
      },
      {
        input: 'ㅃ',
        expected: [['ㅃ', 'ㅄ'], ['빠', '사']/*, ['ㅂㅂ','ㅂㅅ']*/],
      },
      {
        input: 'ㅄ',
        expected: [['ㅄ', 'ㅅ'], ['ㅂㅅ', 'ㅂㅆ'], ['ㅂ사', 'ㅂ싸']],
      },
      {
        input: 'ㅅ',
        expected: [['ㅅ', 'ㅆ'], ['사', '싸']],
      },
      {
        input: 'ㅆ',
        expected: [['ㅆ', 'ㅇ'], ['싸', '아']/*, ['ㅅㅅ', 'ㅅㅆ']*/],
      },
      {
        input: 'ㅇ',
        expected: [['ㅇ', 'ㅈ'], ['아', '자']],
      },
      {
        input: 'ㅈ',
        expected: [['ㅈ', 'ㅉ'], ['자', '짜']],
      },
      {
        input: 'ㅉ',
        expected: [['ㅉ', 'ㅊ'], ['짜', '차']/*, ['ㅈㅈ', 'ㅈㅉ']*/],
      },
      {
        input: 'ㅊ',
        expected: [['ㅊ', 'ㅋ'], ['차', '카']],
      },
      {
        input: 'ㅋ',
        expected: [['ㅋ', 'ㅌ'], ['카', '타']],
      },
      {
        input: 'ㅌ',
        expected: [['ㅌ', 'ㅍ'], ['타', '파']],
      },
      {
        input: 'ㅍ',
        expected: [['ㅍ', 'ㅎ'], ['파', '하']],
      },
      {
        input: 'ㅎ',
        expected: [['ㅎ', 'ㅏ'], ['하', TestKorean.chLast]],
      },
    ];
  }

  runTest(input : string) : string[][] {
    // do something with input'
    return Korean.getSuccessorRange(input);
  }

  equals(expected : string[][], actual : string[][]) : boolean {
    if (expected.length !== actual.length)
      return false;
    
    for (let i = 0; i < expected.length; i++) {
      if (expected[i].length !== actual[i].length)
        return false;
      
      for (let j = 0; j < expected[i].length; j++) {
        if (expected[i][j] !== actual[i][j])
          return false;
      }
    }

    return true;
  }
}

