import React from 'react';
import {FlatList, View, StyleSheet} from 'react-native';
import {T} from './T';

import {TestResult} from '../testLib/tests';

interface TestProps {
  data : TestResult[];
}

interface TestStates {}

export default class TestComponent extends React.Component<TestProps, TestStates> {
  render() {
    return (
      <FlatList
        style={{paddingLeft: 20, paddingRight: 20}}
        data={this.props.data}
        ItemSeparatorComponent={() => (<View style={{height: 1, backgroundColor: '#000'}}></View>)}
        renderItem={({item}) => {
          const st = item.isSuccess ? 'SUCCESS' : 'FAIL';
          const color = item.isSuccess ? 'green' : 'red';
          return (
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
              <T style={[{color, minWidth: 80}, styles.fontStyle]}>{st}</T>
              <T style={styles.fontStyle}>{JSON.stringify(item.input)}</T>
              <T style={styles.fontStyle}>{JSON.stringify(item.expected)}</T>
              <T style={styles.fontStyle}>{JSON.stringify(item.actual)}</T>
            </View>
          );
        }}
      />
    );
  }
}

const styles = StyleSheet.create({
  fontStyle: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'courier',
  }
});