import Util from './util';
import {
  defaults, SignHelper, UserInfo, RecordMethods, GetStatMethods, ChangeMethods,
  GetHintItems, GetDictionary, GetUserDoc, GetWordsByUid, GetNominatedByUid, GetUserMessage, GetNominated, GetAllNominated,
  GetDictionaryById, GetSearchHistory,
  RemoveNominated, RemoveSearchHistory,
} from './firebaseUtil';
import {KeyedData, WithId, IHaveKey, ItemResult, AsBoolean} from './commons';
import * as tdb from './db';
import { LocalInfo } from './localInfo';
import {Manager, ManagerGreedy, ManagerWithOne, GetParam} from './manager';
import Cache from './cache';

//// DEBUG VARIABLE
const debug_HM_extensive = false;

//// Parameters
const numberOfHintsToLoad = 18;
const numberOfResultsToLoad = 9;

/*
  Question: do this?
  1. HintManager should handle q with special characters
    e.g. Given [queryString]
      ["word"]: look for an EXACT match
      [word+]: look for words that start with word
  2. Implement GetQresExact which is same as GetQres except q = `"${q}"`.
*/

export type WithAddedBy<T> = T & addedBy & {uid: string};

export type Def = WithId<{meaning: string, phrase: string[]}> & tdb.IWord & tdb.IVotable & tdb.ITimed;
export type NomItem = WithId<tdb.Nominated> & addedBy;
export type WordItem = WithId<tdb.Dictionary> & addedBy;
export type HintItem = WithId<tdb.DictionaryHint>;
export type MsgItem = WithId<tdb.UserMessage>;
export type SearchHistoryItem = WithId<tdb.HistoryItem>;

type addedBy = {addedBy: string};

const dv : {
  Def: Def,
  NomItem: NomItem,
  User: WithId<tdb.User>,
  HintItem: HintItem,
  WordItem: WordItem,
  SearchHistoryItem: SearchHistoryItem,
} = {
  Def: {
    id: '',
    meaning: '',
    phrase: [],
    word: '',
    actual: null,
    n: -1,
    vote: -1,
    timestamp: defaults.timestamp
  },
  NomItem: {
    id: '',
    word: '',
    actual: null,
    n: -1,
    timestamp: defaults.timestamp,
    lastModified: defaults.timestamp,
    meaning: '',
    phrase: [],
    uid: '',
    vote: -1,
    score: -1,
    addedBy: '',
  },
  User: {
    id: '',
    alias: '<nonamed>',
    rank: -1,
    geopoint: defaults.geopoint,
    timestamp: defaults.timestamp,
  },
  HintItem: {
    id: '',
    numDef: -1,
    actual: null,
    timestamp: defaults.timestamp
  },
  WordItem: {
    id: '',
    uid: '',
    word: '',
    actual: null,
    n: -1,
    timestamp: defaults.timestamp,
    vote: -1,
    meaning: '',
    phrase: [],
    addedBy: '',
  },
  SearchHistoryItem: {
    id: '',
    query: '',
    timestamp: defaults.timestamp,
  }
}

// todo:
export interface Spop {}

// todo: limit number of items to fetch (stop extending Greedy version).
export class NomManager extends ManagerGreedy<string, NomItem> {
  private get query(): string {
    return this.dep;
  }

  protected _getPreloadItem = () => dv.NomItem;
  protected _getQuerySnapshot = () => GetNominated(this.query);
  protected _extendData = Remote.ExtendUserInfo;
}

export class RefManager extends ManagerWithOne<string, WithId<tdb.User>> {
  private get uid(): string {
    return this.dep;
  }

  protected _getPreloadItem = () => dv.User;
  protected _getDocumentSnapshot = () => GetUserDoc(this.uid);
  protected _extendData = undefined;
}

// must create a new one per query
export class HintManager extends Manager<string, HintItem> {
  private ranges: string[][];
  private irange: number;

  public debug_log: string[];
  public debug_counter: number; 

  public static PreLoading: HintItem = dv.HintItem;
  protected _getPreloadItem = () => dv.HintItem;

  private get query(): string {
    return this.dep;
  }

  constructor(query: string) {
    super(query);

    this.ranges = Util.FindPredecessor(query);
    this.irange = 0;

    this.debug_log = [`Created: ${JSON.stringify(this.ranges)} query[${this.query}]`];
    this.debug_counter = 0;
  }

  // No data if query is an empty string.
  protected _hasNext(): boolean {
    return this.query !== "" && this.irange < this.ranges.length;
  }

  // Get next n data for the set query.
  // return a reference to the array that holds all data that we have gotten so far
  // for the set query; not just the data that we just got by calling this method.
  // noteToSelf: if we want 'new data' only, return the subarray based on this.data and querySnapshot.size
  protected async _getNext(param: GetParam): Promise<HintItem[]> {
    this.dlog(`GetNext query for num[${param.n}] results.`);
    this.dlog(`irange[${this.irange}], lastItem[${this.dlastItem()}]`);
    this.dlog(`current Data: ${JSON.stringify(this._debugGetData())}`, 1);

    const debug_while_counter = 0;
    while (param.n > 0 && this._hasNext()) {
      this.dlog(`while[${debug_while_counter}]: num[${param.n}] irange[${this.irange}] lastItem[${this.dlastItem()}]`, 1);

      // await is necessary because we don't know how many hints we will get with current 'range'
      const querySnapshot = await this.populateData(this.ranges[this.irange], param.n);
      this.dlog(`Got: [${querySnapshot.size}]`, 2);

      let numRes = 0;
      querySnapshot.docs
        .forEach(doc => {
          if (doc.exists) {
            this.dlog(`Processing Item: ${doc.id}`, 3);
            this.addData(doc);
            numRes++;
          }
        });

      this.dlog(`Processed: [${numRes}]`, 2);

      param.n -= numRes;

      if (param.n < 0)
        throw new Error("Got more than what was asked for");

      // if we could not consume num; it means that the current range
      // is out of results; therefore move onto the next range.
      if (param.n > 0) {
        this.dlog(`Incrementing irange[${this.irange}]`, 2);
        this.irange++;
      }
    }

    return this.returnData()
  }

  protected _populate = GetHintItems;
  protected _extendData = undefined;

  public dlog(s: string, level?: number) {
    if (debug_HM_extensive)
      this.debug_log.push(`${"  ".repeat(level ? level : 0)}${s}`);
  }

  public dlogOut() {
    this.debug_log.forEach(log => console.log(log));
  }
}

// needs remote s.t. it can get a hold of the relevant HintManager
type ResDependency = { remote: Remote, query: string, exact: boolean } & IHaveKey;

export class ResManager extends Manager<ResDependency, WordItem> {
  private iNext: number; // index in hm.data

  public static Preloading: WordItem = dv.WordItem;
  protected _getPreloadItem = () => dv.WordItem;

  constructor(dep: ResDependency) {
    super(dep);
    this.iNext = 0; // [iNext, ...) has not been loaded.
  }

  // if HintManager HasNext, we might not have asked the database yet
  protected _hasNext = (): boolean => {
    const hm = this.dep.remote._getHM(this.dep.query);
    return this.dep.exact ? this.iNext < 1 : hm.HasNext() || this.iNext < hm.NumItems();
  };

  protected _populate = GetDictionary;
  protected _extendData = (word: WordItem) => {
    this.dep.remote.stat.Record['OnLoadWord'](word.actual ? word.actual : word.word);
    return Remote.ExtendUserInfo(word);
  };

  protected _getNext(param: GetParam): Promise<WordItem[]> {
    // We query for n words from database, but we might end up getting more than n
    // definitions since there are words that have multiple meanings.
    // We take n of them, and put everything else in the buffer.
    return this._getWords(param.n).then(words => this._loadMore(words, param));
  }

  protected _onLoad = () => {
    // RecordOnSearch(SignHelper.I.GetUserToken(), this.dep.query); // for stats.
  }

  // would've used it as 'get' if parameter wasn't there.
  // get the list of words to get results for.
  // Get Hints from database using HintManager if we aren't looking for exact match.
  private _getWords(n: number): Promise<string[]> {
    if (this.dep.exact)
      return Promise.resolve([this.dep.query]);

    const hm = this.dep.remote._getHM(this.dep.query);
    const nhmNeeded = this.iNext + n - hm.NumItems();
    return nhmNeeded > 0
      ? hm.GetMore({n: nhmNeeded}).then(ir => ir.data.map(hint => hint.id))
      : hm.GetNext({n: 0}).then(data => data.map(hint => hint.id));
  }

  // updates this.iNext
  private async _loadMore(words: string[], param: GetParam) : Promise<WordItem[]> {
    if (words.length <= this.iNext)
      return Promise.resolve([]);
    
    const promises: Promise<firebase.firestore.QuerySnapshot>[] = [];
    const itarget = this.iNext + param.n;

    // Get all data asap
    while (this.iNext < words.length && this.iNext < itarget) {
      const word = words[this.iNext];
      promises.push(this.populateData(word, NaN));
      this.iNext++;
    }

    // wait sequentially s.t. the order of data is kept.
    for(let i = 0; i < promises.length; i++)
      (await promises[i]).forEach(doc => this.addData(doc));

    return this.returnData();
  }
}

class WordManager extends ManagerWithOne<string, Def> {
  private get id() {
    return this.dep;
  }

  protected _getPreloadItem = () => dv.Def;
  protected _getDocumentSnapshot = () => GetDictionaryById(this.id);
  protected _extendData = undefined;
}

// Figure out how to update the data in a scenario where
// user views search history, closes it
// searches an item, then view search history again. (maybe use snapshot)
class SearchHistoryManager extends Manager<string, SearchHistoryItem> {
  private get uid() {
    return this.dep;
  }

  protected _getPreloadItem = () => dv.SearchHistoryItem;
  protected _hasNext = () => true;
  protected _extendData = undefined;

  protected _populate = GetSearchHistory;

  protected _getNext(param: GetParam): Promise<SearchHistoryItem[]> {
    return this.populateData(this.uid, param.n).then(qs => {
      qs.docs.forEach(ds => this.addData(ds));
      return this.returnData();
    });
  }
}

class RecordHelper {
  public static I = new RecordHelper();

  public get Get() {
    return RecordHelper._map_get;
  }

  // todo: handle annonymous user when uid === ''
  public get Record() {
    return RecordHelper._map_record;
  }

  private static _map_get = {
    RecentlyAdded: (n: number) => Remote.ExtendUserInfoAll(GetStatMethods.RecentlyAdded(n)),
    MostVoted: (n: number) => Remote.ExtendUserInfoAll(GetStatMethods.MostVoted(n)),
    PopularAllTime: (n: number) => GetStatMethods.PopularAllTime(n),
    PopularToday: (n: number) => GetStatMethods.PopularByDate(n, new Date()),
    PopularThisMonth: (n: number) => GetStatMethods.PopularByMonth(n, new Date()),
  }

  private static _map_record = {
    AddHistoryOnSearch: (query: string) => RecordMethods.AddHistoryOnSearch(SignHelper.I.GetUserToken(), query),
    OnLoadWord: RecordMethods.OnLoadWord,
  }
}

class CommitHelper {
  public static I = new CommitHelper();

  private _map_remove: KeyedData<(data: any) => Promise<any>>;

  private static _map_change = {
    AddNewWord: (word: string, meaning: string, phrase: string) => 
      ChangeMethods.AddDictionary(SignHelper.I.GetUserToken(), word, meaning, phrase),
    NominateWord: (word: string, meaning: string, phrase: string) => 
      ChangeMethods.AddNominated(SignHelper.I.GetUserToken(), word, meaning, phrase),
    PromoteNominated: ChangeMethods.PromoteNominated,
    // using uid as the id ensures that no one can like the same instance more than once.
    VoteupDictionary: (item: WordItem): Promise<boolean> => {
      return Promise.resolve(SignHelper.I.HasUser()).then(hasUser => {
        if (!hasUser)
          throw new Error("Cannot change vote when not logged in");

        // Local change.
        item.vote++;
        SignHelper.I.AddFavorite(item);

        return AsBoolean(ChangeMethods.VoteupDictionary(SignHelper.I.GetUserToken(), item.id));            
      });
    },
    VotedownDictionary: (item: WordItem): Promise<boolean> => {
      return Promise.resolve(SignHelper.I.HasUser()).then(hasUser => {
        if (!hasUser)
          throw new Error("Cannot change vote when not logged in");

        // Local change.
        item.vote--;
        SignHelper.I.RemoveFavorite(item);

        return AsBoolean(ChangeMethods.VotedownDictionary(SignHelper.I.GetUserToken(), item.id));
      });
    },
  }

  constructor() {
    this._map_remove = {
      RemoveNominated: (obj: {id: string}) => {
        return AsBoolean(RemoveNominated(obj.id));
      },
      RemoveSearchHistory: () => RemoveSearchHistory(SignHelper.I.GetUserToken()),
    }
  }

  public get Change() {
    return CommitHelper._map_change;
  }

  public Remove<T>(name: string, obj?: T): Promise<boolean> {
    return this._map_remove[name](obj).then(() => true).catch(() => false);
  }
}

export default class Remote {
  // todo: take in default parameters for connection settings
  private cacheHM : Cache<string, HintManager>;
  private cacheRM : Cache<ResDependency, ResManager>;
  private cacheRefM : Cache<string, RefManager>;
  private cacheNomM : Cache<string, NomManager>;
  private cacheWordM : Cache<string, WordManager>;
  private cacheSHistM: Cache<string, SearchHistoryManager>;

  private ch: CommitHelper;
  private rh: RecordHelper;

  public local: LocalInfo;

  public static I = new Remote();

  public get set() {
    return this.ch;
  }

  public get stat() {
    return this.rh
  }

  constructor() {
    this.cacheHM = new Cache(HintManager);
    this.cacheRM = new Cache(ResManager);
    this.cacheRefM = new Cache(RefManager);
    this.cacheNomM = new Cache(NomManager);
    this.cacheWordM = new Cache(WordManager);
    this.cacheSHistM = new Cache(SearchHistoryManager);

    // todo: user this info (e.g. geoLocation) when searching.
    this.local = LocalInfo.I;
    this.ch = CommitHelper.I;
    this.rh = RecordHelper.I;
  }

  // public s.t. it can be used by ResManager to get cached HintManager.
  public _getHM = (q: string) => this.cacheHM.Get(q);
  private _getRM = (q: string, exact: boolean = false) => {
    const query = q.toLocaleLowerCase();
    return this.cacheRM.Get({remote: this, query, exact, GetKey: () => `${query}-${exact}`});
  }
  private _getRefM = (uid: string) => this.cacheRefM.Get(uid);
  private _getNomM = (q: string) => this.cacheNomM.Get(q);
  private _getWordM = (id: string) => this.cacheWordM.Get(id);
  private _getSHistM = (uid: string) => this.cacheSHistM.Get(uid);

  // First time is a special case;
  // if we have never gotten any data yet, we must try to get data.
  // Otherwise, just grab whatever data we have gathered already.
  // Caller must call GetQhintMore whenever 'more data' is necessary.
  public GetQhint = (q: string) : Promise<HintItem[]> => this._getHM(q).GetNext({n: numberOfHintsToLoad});
  public GetQhintMore = async (q: string) : Promise<ItemResult<HintItem>> => this._getHM(q).GetMore({n: numberOfHintsToLoad});

  public GetQres = (q: string) : Promise<WordItem[]> => this._getRM(q).GetNext({n: numberOfResultsToLoad});
  public GetQresMore = (q: string) : Promise<ItemResult<WordItem>> => this._getRM(q).GetMore({n: numberOfResultsToLoad});

  public GetQresExact = (q: string) : Promise<WordItem[]> => this._getRM(q, true).GetNext({n: numberOfResultsToLoad});

  public GetQnom = (q: string) : Promise<NomItem[]> => this._getNomM(q).GetAll();

  public GetAllNominated = (): Promise<NomItem[]> =>  Remote.ExtendUserInfoAll(GetAllNominated());

  public GetUserOwnedItems = (type: UserInfo) => this._getMyList[type]();

  public GetWordById = (wid: string) => this._getWordM(wid).GetOne();

  public GetAddedWordsByUid = (uid: string): Promise<Def[]> => GetWordsByUid(uid);

  //{[k in MyListType]: (uid: string, type: MyListType) => Promise<MyLists>} =
  private _getMyList = {
    [UserInfo.Added]: () => GetWordsByUid(SignHelper.I.GetUserToken()), // this result is cached by firestore so i don't need to cache it manually.
    [UserInfo.Favorite]: () => Promise.resolve(SignHelper.I.GetFavorite()),
    [UserInfo.Nominated]: () => GetNominatedByUid(SignHelper.I.GetUserToken()),
    // todo: user manager?
    [UserInfo.Message]: () => GetUserMessage(SignHelper.I.GetUserToken()),
    [UserInfo.SearchHistory]: () => this.GetSearchHistory(),
  }

  public GetPublicUserRecord = (uid: string): Promise<tdb.User> => this._getRefM(uid).GetOne();

  public GetPublicUserInfo<T extends keyof tdb.User>(uid: string, fieldName: T): Promise<tdb.User[T]> {
    return this._getRefM(uid).GetOne().then(user => {
      if (user)
        return user[fieldName];
      
      throw new Error("No User found");
    });
  } 

  public GetAuthority = (uid?: string) =>
    this.GetPublicUserInfo(uid ? uid : SignHelper.I.GetUserToken(), "rank").then(rank => rank === 1);

  public GetSearchHistory = () => this._getSHistM(SignHelper.I.GetUserToken()).GetNext({n: numberOfHintsToLoad});

  // todo: remove | null
  // t: key for determining exactly which popularity data to get.
  public GetStatPopularity(/* t : string */) : Spop | null {
    return null;
  }

  public static NotUndef<T>(x: T | undefined): x is T {
    return x !== undefined;
  }

  public static ExtendUserInfoAll<T extends {uid: string}>(p: Promise<T[]>) {
    return p.then(data => Promise.all(data.map(Remote.ExtendUserInfo)));
  }

  public static ExtendUserInfo<T extends {uid: string}>(obj: T): Promise<T & {addedBy: string}> {
    const res = obj as T & {addedBy: string};
    return Remote.I.GetPublicUserRecord(obj.uid).then(user => {
      res.addedBy = user.alias;
      return res;
    })
  }
}