export class LocalInfo {
  public static I = new LocalInfo();

  public get geoLocation() {
    return this._geoLocation;
  }

  private _geoLocation: Coordinates | null;

  constructor() {
    this._geoLocation = null;
    this.GetGeoLocation();
  }

  // asks for permission and if allowed, get the geolocation.
  public GetGeoLocation() {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        pos => this._geoLocation = pos.coords,
        err => console.log(err.message)
      );
    }
  }
}