import React from 'react';
import {View, TouchableOpacity, StyleProp, ViewStyle} from 'react-native';
import {T} from './T';

export enum ButtonStyle {
  Stuck,
  MoveAlong,
}

interface CBProps {
  style?: StyleProp<ViewStyle>;
  isCollapsedDefault: boolean;
  enable: boolean;
  openDirection: 'up' | 'down';
  guideline: boolean;

  buttonStyle: ButtonStyle;
  buttonLabel: string[]; // buttonLabel for [collapsed, open]
  title?: string; // if renderTitle is defined, title is ignored.
  renderTitle?: () => JSX.Element;
  renderButton: null | ((label: string) => JSX.Element);

  onChange?: (isCollapsed: boolean) => void,
}

interface CBStates {
  isCollapsed: boolean;
}

export default class CollapsibleBox extends React.Component<CBProps, CBStates> {
  static defaultProps = {
    isCollapsedDefault: true,
    enable: true,
    openDirection: 'down',
    guideline: true,

    buttonStyle: ButtonStyle.Stuck,
    buttonLabel: ['열기 (open)', '닫기 (close)'],
    renderButton: (label: string) => (
      <View style={{width: 81, height: 20, backgroundColor: 'rgba(0,0,0,0.4)', alignItems: 'center', justifyContent: 'center'}}>
        <T>{label}</T>
      </View>
    ),
  };

  constructor(props: CBProps) {
    super(props);

    this.state = {
      isCollapsed: props.isCollapsedDefault ? props.isCollapsedDefault : false,
    }
  }

  public IsEnabled = (): boolean => this.props.enable;

  public Open = (): Promise<boolean> => new Promise(resolve => this._OpenClose(true, resolve));
  public Close = (): Promise<boolean> => new Promise(resolve => this._OpenClose(false, resolve));

  private _shouldBeCollapsed = () => !this.props.enable || this.state.isCollapsed;

  // fOpen === true, open
  // fOpen === false, close
  // fOpen === undefined, flip.
  private _OpenClose = (fOpen?: boolean, onStateChange?: (isCollapsed: boolean) => void) => {
    const {enable, onChange} = this.props;

    if (enable) {
      const isCollapsed = fOpen === undefined ? !this.state.isCollapsed : !fOpen;
      this.setState({isCollapsed}, onStateChange ? () => onStateChange(this.state.isCollapsed) : undefined);
      if (onChange)
        onChange(isCollapsed);
    }
  }

  private _renderTitle = () => {
    const {title, renderTitle} = this.props;
    return renderTitle
      ? renderTitle()
      : title
        ? (<T style={{fontSize: 18, fontWeight: 'bold'}}>{title}</T>)
        : null;
  }

  private _getButtonLabel = (isCollapsed: boolean) => isCollapsed ? this.props.buttonLabel[0] : this.props.buttonLabel[1];

  private _renderHeader = (shouldBeCollapsed: boolean) => {
    const onPress = () => this._OpenClose();
    const {renderButton} = this.props;

    if (renderButton === null)
      return (<TouchableOpacity onPress={onPress}>{this._renderTitle()}</TouchableOpacity>);
      
    return (
      <View style={{flexDirection: 'row', alignItems: 'flex-end', justifyContent:'space-between'}}>
        <View style={{flex: 1}}>
          {this._renderTitle()}
        </View>
        <View>
          {this.IsEnabled()
            ? (<TouchableOpacity onPress={onPress}>{renderButton(this._getButtonLabel(shouldBeCollapsed))}</TouchableOpacity>)
            : null}
        </View>
      </View>
    )
  }

  render() {
    const shouldBeCollapsed = this._shouldBeCollapsed();
    const {style, openDirection, buttonStyle, guideline} = this.props;

    const styleDirection: StyleProp<ViewStyle> =
      openDirection === 'up' || buttonStyle === ButtonStyle.Stuck
        ? {flexDirection: 'column-reverse'}
        : {flexDirection: 'column'};

    const styleLine = guideline && shouldBeCollapsed ? {borderBottomWidth: 1} : {};

    // TODO: animate top-level View's height (which changes based on the content size).
    return (
      <View style={[style, styleDirection, styleLine]}>
        {/* NEed this view here s.t. content's are not reversed in order when styleDirection is reversed */}
        <View>
          { shouldBeCollapsed ? null : this.props.children}
        </View>
        {this._renderHeader(shouldBeCollapsed)}
      </View>
    );
  }
}