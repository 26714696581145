import {CacheKey, IHasId, ItemResult} from './commons';

export type GetParam = {n: number};

type PromiseStatus<T> = { promise: Promise<T>, isWaiting: boolean };
type ItemSnapshot = firebase.firestore.DocumentSnapshot | null;

// D: Dependency, T: ResultItem
// Note that returned data may contain 'preloading indicator item' at the end of the array. 
// GetNext: the first call return a promise that fetches data.
//          subsequent calls to GetNext will return the promise created by the first call.
// GetMore: only does work if there aren't any existing calls to GetNext or GetMore.
export abstract class Manager<D extends CacheKey, T extends IHasId> {
  protected dep: D;

  private _data: T[]; // data stored
  private _extendPromises: Promise<any>[]; // extra work that needs to be waited before ReturnData.
  private _buffer: firebase.firestore.DocumentSnapshot[]; // place to store extra data
  private _numItemNeeded: number; // number of items to return (extra will be stored in _buffer)
  private _itemLast: ItemSnapshot; // last item we got from db.
  private _isLoading: boolean; // true if manager is waiting for results

  // These are used to properly handle Multiple calls to GetMore.
  private _firstPromise: PromiseStatus<T[]>;
  private _morePromise: PromiseStatus<T[]>;

  protected _debugGetData = () => this._data;
  private _errMsgDB = (callsite: string) => `ErrorDB at ${callsite} with ${JSON.stringify(this.dep)}`;

  /**** Implement these: ****/
  // checks to see if client has tried to fetch all relevant data.
  protected abstract _hasNext() : boolean;

  // Must return a singleton
  protected abstract _getPreloadItem(): T;

  // These methods should only be implemented, and NOT used directly (not even by subclasses).
  protected abstract _getNext(param: GetParam) : Promise<T[]>;
  protected abstract _populate(input: any, n: number, itemLast: ItemSnapshot): Promise<firebase.firestore.QuerySnapshot>;
  protected abstract _extendData: ((item: T) => Promise<any>) | undefined;

  constructor(dep: D) {
    this.dep = dep;
    this._data = [];
    this._extendPromises = [];
    this._buffer = [];
    this._numItemNeeded = 0;
    this._itemLast = null;
    this._isLoading = false;
    this._firstPromise = {promise: Promise.resolve(this._data), isWaiting: false};
    this._morePromise = {promise: Promise.resolve(this._data), isWaiting: false};
  }

  public NumItems = () => this._data.length;
  public HasNext = () => this._isLoading || this._hasNext();

  // Multiple calls to GetNext will always give back the same promise that was created by the first call.
  // i.e. GetNext will only do work if no data and no existing promise for data.
  public GetNext(param: GetParam) : Promise<T[]> {
    // create a new promise and store it.
    if (!this.anyData() && !this._firstPromise.isWaiting && this._hasNext()) {
      // check for the first time; (it's ok if we just don't have any data).
      this._firstPromise = {
        isWaiting: true,
        promise: this._startLoading(param, true)
          .then(data => {
            this._firstPromise.isWaiting = false;
            return data;
          })
          .catch((e) => {
            console.error(this._errMsgDB(`GetNext(${param.n})`));
            console.error(e.stack);
            return this._data;
          })
      };
    }

    // if we have an outstanding promise, it will return that promise
    // otherwise, it will return current data (which was the initial value).
    return this._firstPromise.promise;
  }

  // GetMore will only get more if there isn't any outstanding promise
  public GetMore(param: GetParam) : Promise<ItemResult<T>> {
    const numBefore = this._data.length;

    if (this.HasNext()) {
      if (this._firstPromise.isWaiting) {
        // note
        // 1. hasChange might be true in this case since numBefore might change due to fpStatus.promise's work.
        // 2. mpStatus.isWaiting is set to false, s.t.
        //    if we do comeback after fp is done, we will correctly do work.
        this._morePromise = {
          isWaiting: false,
          promise: this._firstPromise.promise
        };
      } else if (!this._morePromise.isWaiting) {
        this._morePromise = {
          isWaiting: true,
          promise: this._startLoading(param, false)
            .then(data => {
              this._morePromise.isWaiting = false;
              return data;
            })
            .catch(e => {
              console.error(this._errMsgDB(`GetMore(${param.n})`));
              console.error(e.stack);
              return this._data;
            })
        }
      }
    }

    return this._morePromise.promise.then(data => {
      return {data, hasChange: data.length !== numBefore};
    });
  }

  // Protected methods
  protected anyData = () => this._data.length > 0;

  // Must have a preloading Item.
  protected addData(doc: firebase.firestore.DocumentSnapshot) {
    this._itemLast = doc;

    if (isNaN(this._numItemNeeded) || this._numItemNeeded > 0) {
      // this._itemLimit--;

      this._preloader.remove();
      this._addData(doc);
      this._preloader.add();
    } else {
      this._buffer.push(doc);
    }
  }

  protected returnData = () => Promise.all(this._extendPromises).then(() => {
    this._extendPromises = [];
    return this._data;
  });

  protected populateData = (input: any, n: number) => this._populate(input, n, this._itemLast);

  protected _onLoad = () => {};

  private _preloader = {
    has: () => this._data.length > 0 && this._data[this._data.length-1] === this._getPreloadItem(),
    add: () => {
      if (this._isLoading) {
        console.error("Adding too many preloading indicator");
        return;
      }
  
      this._isLoading = true;
      this._data.push(this._getPreloadItem());
    },
    remove: () => {
      if (!this._isLoading)
        console.error("Is not preloading, but trying to remove Preload Item");
  
      this._isLoading = false;
      // and if we actually don't have any preload item, make sure to just return.
      if (!this._preloader.has()) {
        console.error("Trying to remove none Preload item");
        return;
      }
  
      this._data.pop();
    },
  }

  // any additional items will go into buffer.
  private _setNumItemNeeded(n: number) {
    this._numItemNeeded = n;
  }

  private _startLoading(param: GetParam, first: boolean): Promise<T[]> {
    this._preloader.add();

    if (first)
      this._onLoad();

    return this._startLoading_helper(param).then(() => {
      this._preloader.remove();
      return this.returnData();
    });
  }

  private _startLoading_helper(param: Readonly<GetParam>): Promise<any> {
    // buffer contains items that we've got already
    const num = param.n - this._moveFromBuffer(param.n);
    if (num <= 0)
      return Promise.resolve();

    // if we consumed the buffer, and need more items:
    const paramNew: GetParam = Object.assign({}, param);
    paramNew.n = num;

    this._setNumItemNeeded(paramNew.n);
    return this._getNext(paramNew);
  }

  private _moveFromBuffer(num: number) : number {
    this._preloader.remove();
    const bufferMove = this._buffer.slice(0, num);
    bufferMove.forEach(doc => this._addData(doc));
    this._buffer = this._buffer.slice(num);
    this._preloader.add();
    return bufferMove.length;
  }

  private _addData(doc: firebase.firestore.DocumentSnapshot) {
    const item = doc.data() as T;
    item.id = doc.id;
    
    if (this._extendData) {
      this._extendPromises.push(this._extendData(item));
    }

    this._data.push(item);
  }

  protected dlastItem() : string {
    return this._itemLast ? this._itemLast.id : "none";
  }
}

// todo: abstract class ManagerGreedy with this._hasNext field.
export abstract class ManagerGreedy<D extends CacheKey, T extends IHasId> extends Manager<D, T> {
  private _hasNext_: boolean;

  protected abstract _getQuerySnapshot(): Promise<firebase.firestore.QuerySnapshot>;
  protected _populate(): Promise<firebase.firestore.QuerySnapshot> {throw new Error("Invalid Operation: fetch data directly");}

  constructor(dep: D) {
    super(dep);
    this._hasNext_ = true;
  }

  // number passed to GetNext is ignored.
  public GetAll = (): Promise<T[]> => this.GetNext({n: NaN});

  protected _hasNext = (): boolean => this._hasNext_;

  protected _getNext(_param: GetParam): Promise<T[]> {
    this._hasNext_ = false;
    return this._getQuerySnapshot().then(qs => {
      qs.docs.forEach(doc => {
        this.addData(doc);
      });
      return this.returnData();
    });
  }
}

export abstract class ManagerWithOne<D extends CacheKey, T extends IHasId> extends Manager<D, T> {
  private _hasNext_: boolean;

  protected abstract _getDocumentSnapshot(): Promise<firebase.firestore.DocumentSnapshot>;
  protected _populate(): Promise<firebase.firestore.QuerySnapshot> {throw new Error("Invalid Operation: fetch data directly");}

  constructor(dep: D) {
    super(dep);
    this._hasNext_ = true;
  }

  public GetOne(): Promise<T> {
    return this.GetNext({n: NaN}).then(t => {
      if (t.length > 1)
        console.error("expecting one data but got multiple");

      return t[0];
    });
  }

  protected _hasNext = (): boolean => this._hasNext_;

  protected _getNext() {
    this._hasNext_ = false;
    return this._getDocumentSnapshot().then(ds => {
      this.addData(ds);
      return this.returnData();
    });
  }
}