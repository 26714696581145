import React, {Fragment} from 'react';
import {Helmet} from 'react-helmet';
import {View, TouchableOpacity, StyleProp, ViewStyle} from 'react-native';

import Remote, {Def, WordItem, HintItem} from '../lib/remote';

import {T} from './T';
import {ResList, ResData} from './resList';
import {RenderDefCollapsible} from './resultBox';
import {ResItemSS} from './snapshot';

export enum PageType {
  Loading,
  Search,
  User,
  Stat,
  Error,
}

type PageProp<D> = {
  remote: Remote,

  // this must not be evaluated to 'false'
  // Page will run whenever dep changes, where 'change' is determined by
  // doing a shallow comparison. 
  dep: D,
  style?: StyleProp<ViewStyle>,
};

// wrapper on ResData s.t. we can keep the same reference for data.
type PageState<T, D> = {
  data: ResData<T>,
  _depPrev: D,
}

// D: type of dep, S: type of data
// Make sure that props don't ever change except for dep
// so that render of a page is only caused by change in dep or change in state.data.
// todo: This can be PureComponenet if we know flatlist can some how detect change in data (i.e. data.length changed).
abstract class Page<D, P extends PageProp<D>, S> extends React.Component<P, PageState<S, D>> {
  constructor(props: P) {
    super(props);

    this.state = {
      data: undefined,
      _depPrev: props.dep,
    };
  }

  componentDidMount() {
    // initial mount is considered as a change because
    // it goes from no dep to some dep.
    if (this.props.dep)
      this._populate_();
  }

  getSnapshotBeforeUpdate(prevProps: PageProp<D>) {
    if (this.props.dep && prevProps.dep !== this.props.dep)
      this._populate_();

    return null;
  }

  // If this component is being updated b/c of change in prop.dep
  //   we know that we must try fetching new data.
  //   Therefore we must set data to undefined in this case, but we can just skip that
  //   and set it directly to empty array.
  // We don't need call this for initial mount and this won't.
  // IF we wanted to keep previous results (data) until newly fetched data comes back
  //   then we need to set data to [] only if data === null.
  static getDerivedStateFromProps<D>(props: PageProp<D>, state: PageState<any, D>): Partial<PageState<any, D>> | null {
    if (state._depPrev !== props.dep) {
      return {
        data: [],
        _depPrev: props.dep
      };
    }

    return null;
  }

  // set b/c we defined getSnapshotBeforeUpdate
  componentDidUpdate() {}

  private _populate_() {
    const runPopulate = this._populate;
    if (runPopulate) {
      ResList.populate(
        this.state.data ? this.state.data : undefined,
        dataNew => this.setState({data: dataNew}),
        () => runPopulate(this.props.remote, this.props.dep)
      );
    }
  }

  protected abstract get _populate(): ((remote: Remote, dep: D) => Promise<S[]>) | undefined;

  protected _refresh = () => this.setState({data: this.state.data});
}

type UserProp = PageProp<string> & {username: string};

export class UserPage extends Page<string, UserProp, Def> {
  protected get _populate() {
    return (remote: Remote, uid: string) => remote.GetAddedWordsByUid(uid);
  }

  render() {
    const {data} = this.state;
    const {style} = this.props;
    return (
      <View style={style}>
        <View>
          <T style={{fontSize: 27, fontWeight: 'bold'}}>{`${this.props.username}(이)가 추가한 단어들`}</T>
        </View>
        <ResList
          style={{padding: 10, }}
          data={data}
          renderItem={item => RenderDefCollapsible(item)}
        />
      </View>
    );
  }
}

type SearchProp = PageProp<string> & {
  showNominate: () => void,
  highlightLogin: () => void,
  onSelectUser: (uid: string) => void,
  onSearch: (q: string) => void,
};

export class SearchPage extends Page<string, SearchProp, WordItem> {
  private get query() {
    return this.props.dep;
  }

  protected get _populate() {
    return (remote: Remote, query: string) => remote.GetQresExact(query);
  }

  render() {
    const {data} = this.state;
    const {remote, onSelectUser, highlightLogin, onSearch, style} = this.props;

    const metaDescription = data && data.length > 0
      ? data[0].meaning
      : "검색 결과가 없습니다.";

    return (
      <Fragment>
        <Helmet>
          <meta property="og:title" content={`인싸미터 - ${this.query}`} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          {/* <meta property="og:image" content="" /> */}
        </Helmet>
        <ResList
          style={style}
          data={data}
          // onGetMore={() => {
          //   if (data && data.length > 0) {
          //     remote.GetQresMore(this.query).then(itemResult =>{
          //       if (itemResult.hasChange)
          //         this.setState({data: itemResult.data});
          //     })
          //   }
          // }}
          ItemSeparatorComponent={() => (<View style={{height:1, backgroundColor: '#CCC', marginTop: 27, marginBottom: 4}}></View>)}
          renderItem={item => (<ResItemSS data={item} remote={remote} onSelectUser={onSelectUser} highlightLogin={highlightLogin} />)}
          renderOnNull={() => {
            if (this.query === '')
              return null;

            return (
              <View style={{alignItems: 'center'}}>
                <T style={{fontSize: 27, fontWeight: 'bold'}}>{`${this.query}에 대한 검색 결과가 없습니다.`}</T>
                <TouchableOpacity
                  style={{marginTop: 10, padding: 4, backgroundColor: '#DDD'}}
                  onPress={() => this.props.showNominate()}>
                  <T>{'단어 추가 하러 가기'}</T>
                </TouchableOpacity>
                {/* Getting data again through HintPage is fine since hint results are cached. */}
                <HintPage
                  remote={remote}
                  dep={this.query}
                  onSearch={onSearch}
                  style={{marginTop: 27, width: '100%', alignItems: 'center', paddingVertical: 10, backgroundColor: '#E0E0E0'}} />
              </View>);
          }}
        />
      </Fragment>
    );
  }
}

type HintProp = PageProp<string> & {onSearch: (q: string) => void};

class HintPage extends Page<string, HintProp, HintItem> {
  protected get _populate() {
    return (remote: Remote, query: string) => remote.GetQhint(query);
  }

  render() {
    const {style, onSearch} = this.props;
    const {data} = this.state;
    return (
      <View style={style}>
        <T style={{fontSize: 18, fontWeight: 'bold', textDecorationLine: 'underline'}}>아래 단어들은 어떤가요?</T>
        <ResList
          data={data}
          renderItem={item => {
            const word = item.actual ? item.actual : item.id;
            return (
              <View style={{alignItems: 'center'}}>
                <TouchableOpacity onPress={() => onSearch(word)}>
                  <T style={{fontSize: 18, padding: 4}}>{word}</T>
                </TouchableOpacity>
              </View>
            )
          }}
        />
      </View>
    );
  }
}

type StatProp<T> = PageProp<StatGetter<T>> & {
  title: string,
  renderItem: (item: Readonly<T>, index: number) => JSX.Element,
};

type StatGetter<T> = () => Promise<T[]>;

const defaultStatStyle: StyleProp<ViewStyle> = {
  borderWidth: 1,
  padding: 18,
  marginHorizontal: 18,
  marginBottom: 18,
};

export class StatPage<T> extends Page<StatGetter<T>, StatProp<T>, T> {
  protected get _populate() {
    return (_remote: Remote, dep: StatGetter<T>) => dep();
  }

  render() {
    const {data} = this.state;
    const {style, title, renderItem} = this.props;
    return (
      <View style={[defaultStatStyle, style]}>
        <T style={{fontSize: 22, fontWeight: 'bold', borderBottomWidth: 1, alignSelf: 'center'}}>{title}</T>
        <View style={{overflow: 'hidden'}}>
          <ResList
            style={{height: 324}}
            overflowY={'scroll'}
            data={data}
            renderItem={renderItem}
          />
        </View>
      </View>
    );
  }
}