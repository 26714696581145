type param = {[k: string]: any};

export function GetParam() {
  return window.location.search.substr(1).split("&").reduce((obj: {[k: string]: string}, token: string) => {
    const [k, v] = decodeURI(token).split("=");
    obj[k] = v ? v : '';
    return obj;
  }, {});
}

export function SetParam(params: param) {
  window.history.pushState(null, '', _createParam(params));
  // // window.location.href = `${window.location.origin}${window.location.pathname}?${k}=${v}`;    
}

export function GetURLWithParam(params: param): string {
  return `${window.location.origin}${_createParam(params)}`;
}

function _createParam(params: param): string {
  return `/?${Object.keys(params).filter(k => params[k]).map(k => `${k}=${params[k]}`).join("&")}`;
}