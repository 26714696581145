import Korean from './korean';


const Util = {
  FindPredecessor: (st : string) : string[][] => {
    // todo: get the last character properly
    // todo: get the correct byte number
    // let chLast = parseInt(st[st.length-1]);
    if (Korean.isKorean(st.charCodeAt(st.length-1)))
      return Korean.getSuccessorRange(st);
    else
      return getPrefixSuccessor(st);
  }
}

// might need to deal with last character overflowing..
function getPrefixSuccessor(st : string) : string[][] {
  const front = st.substring(0, st.length-1);
  const charCode = st.charCodeAt(st.length-1);
  return [[st, `${front}${String.fromCharCode(charCode+1)}`]];
}

export default Util;