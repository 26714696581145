import React, { PropsWithChildren, Fragment } from 'react';
import {TouchableWithoutFeedback, View, StyleProp, ViewStyle} from 'react-native';

type OptionalProps = { isVisible: boolean };

export function OptionalBox(props: PropsWithChildren<OptionalProps>): JSX.Element | null {
  if (props.isVisible)
    return (<Fragment>{props.children}</Fragment>);
  else
    return null;
}

type SelectableProps = {
  defaultValue: boolean,
  style: StyleProp<ViewStyle>,
  styleSelected: StyleProp<ViewStyle>,
  onChange?: (selected: boolean) => void,
};

type SelectableStates = {
  selected: boolean,
}

export class Selectable extends React.Component<SelectableProps, SelectableStates> {
  static defaultProps = {
    defaultValue: false,
    style: {
      backgroundColor: '#FFF',
    },
    styleSelected: {
      backgroundColor: '#CCC',
    }
  };

  constructor(props: SelectableProps) {
    super(props);

    this.state = {
      selected: props.defaultValue,
    }
  }

  render() {
    const {style, styleSelected, onChange, children} = this.props;
    const styleSelection = this.state.selected ? styleSelected : style;
    return (
      <TouchableWithoutFeedback
        onPressIn={() => {
          const selected = !this.state.selected;
          this.setState({selected})
          if (onChange)
            onChange(selected);
        }}
      >
        <View style={styleSelection}>
          {children}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}