import React from 'react';
import {Text, TextInput, TextProps, TextInputProps, View, StyleProp, TextStyle} from 'react-native';

interface IState {}

const defaultTextStyles: StyleProp<TextStyle> = {
  fontSize: 12,
  fontFamily: 'arial',

  // ensures that ios and android get same style behavior when multiline is true.
  textAlignVertical: 'top',
}

export class T extends React.Component<TextProps, IState> {
  render() {
    const {style, ...rest} = this.props;
    return (
      <Text style={[defaultTextStyles, style]} {...rest}>
        {this.props.children}
      </Text>
    );
  }
}

export const TI = React.forwardRef((props: TextInputProps & React.RefAttributes<any>, ref: React.Ref<any>) => {
  const {style, ...rest} = props;
  return (
    <TextInput ref={ref} style={[defaultTextStyles, style]} {...rest} />
  );
});


interface ITHighlightedPrefixProps extends TextProps {
  value: string,
  prefix: string, // prefix of the text that should be highlighted
}

// ignores case
export function THighlightedPrefix(props: ITHighlightedPrefixProps) {
  const {value, prefix, style, ...rest} = props;
  if (!value || !value.toLocaleLowerCase().startsWith(prefix.toLocaleLowerCase()))
    return (<T style={style} {...rest}>{value}</T>);

  const textPrefix = value.substring(0, prefix.length);
  const textRest = value.substring(prefix.length);

  // assume normal text is not bold AND fontFamilly differentiates bold vs normal.
  return (
      <View style={{flexDirection: 'row'}}>
        <T {...rest} style={[style, {fontWeight: 'bold'}]}>{textPrefix}</T>
        <T {...rest} style={style}>{textRest}</T>
      </View>
  );
}