import React, { Fragment } from 'react';
import { TouchableOpacity, View, StyleSheet, StyleProp, ViewStyle, TextStyle, Image, Platform } from 'react-native';
import {SignHelper} from '../lib/firebaseUtil';
import { T } from './T';
import Remote, { WordItem, NomItem } from '../lib/remote';
import Forme, { Stage, Inpute, Liste, FormeDataWithInput, StageRedirect } from './form';
import { RenderDefWithAddedBy } from './resultBox';
import { ResItem, ResList, ResData } from './resList';
import CollapsibleBox from './collapsibleBox';
import Timed, {TimedParam} from './timed';

type LoginProps = {highlight?: TimedParam<string>, style?: StyleProp<ViewStyle>};
type CustomFormeProps = {remote: Remote, onSubmit: () => void};

const styleTopButton: StyleProp<ViewStyle> = {
  borderWidth: 1,
  paddingHorizontal: 4,
  paddingTop: 2,
  paddingBottom: 1,
  marginBottom: -1,
  marginRight: 4,
  justifyContent: 'flex-end',
  height: 24,
};

export function LogoBox(): JSX.Element {
  return (
    <TouchableOpacity style={[styleTopButton, {backgroundColor: '#222'}]} onPress={() => window.location.href = window.location.origin}>
      <View style={{flexDirection: 'row'}}>
        <Image style={{width: 16, height: 16, marginRight: 4}} source={{uri: `${window.location.origin}/logo192_white.png`}} />
        <T style={{fontWeight: 'bold', color: '#FFF'}}>인싸미터</T>
      </View>
    </TouchableOpacity>
  )
}

// todo: as we add more provider, we might need to make an expandable list
// for the providers especially for mobile web.
export function LoginBox(props: LoginProps): JSX.Element {
  // minus margins are added b/c image itself has transparent bordering that make it look floating
  return (
    <View style={[{flexDirection: 'row', marginBottom: -3, marginRight: -3}, props.style]}>
      {
        props.highlight
          ? (
            <Timed keyTime={props.highlight.getKey()} duration={props.highlight.duration}>
              <View style={{width: 'calc(100% + 4px)', height: 'calc(100% + 4px)', position: 'absolute', top: -2, left: -2, backgroundColor: props.highlight.obj}}></View>
            </Timed>
          )
          : null
      }
      <TouchableOpacity onPress={() => SignHelper.I.TrySignIn(Platform.OS !== 'web')}>
        <Image style={{width: 191, height: 46}} source={require('../icons/btn_google_signin_light_normal_web@2x.png')} />
      </TouchableOpacity>
    </View>
  );
}

type NominateFormeProps = CustomFormeProps & {highlightLogin: () => void};
export function NominateForm(props: NominateFormeProps): JSX.Element {
  const {remote} = props;
  return (
    <Forme
      style={{borderWidth: 1, padding: 10, backgroundColor: 'azure'}}
      fontSize={18}
      initial={!SignHelper.I.HasUser() ? {notLoggedIn: true} : {}}
      renderEnd={(data) => (
        <View>
          <T>{`${data.word}(이)가 추천 되었습니다.`}</T>
        </View>
      )}
      labelEnd={'다른 단어 추가하기'}
      onSubmit={(_obj) => {
        console.log('each end of the stage should process the data using onData.');
        console.log('todo: we could receive the promise here or the results.');
        console.log('todo: fire update only if data is successfully changed');
        props.onSubmit();
      }}
    >
      <StageRedirect 
        conditional={'notLoggedIn'}
        redirect={null}
        onLoad={props.highlightLogin}>
        <T>단어를 추천하려면 로그인이 필요합니다</T>
      </StageRedirect>
      <Stage
        getTitle={() => '추천할 단어를 입력하세요'}
        onData={data =>
          remote.GetQresExact(data.word).then(words => ResList.isNonEmpty(words) ? {words}
          : remote.GetQnom(data.word).then(noms => ResList.isNonEmpty(noms) ? {noms} : {}))}>
        <Inpute label='단어' name='word' required />
      </Stage>
      <Stage
        getTitle={() => '다음 단어들이 이미 등록되어 있습니다.'}
        onData={data => remote.GetQnom(data.word).then(noms => ResList.isNonEmpty(noms) ? {noms} : {})}
        conditional={'words'}
        renderContent={(_data, words: WordItem[]) => (
          <View>
            <ResList
              style={{paddingBottom: 10}}
              data={words}
              renderItem={item => RenderDefWithAddedBy(item)}
            />
            <T>만약 원하는 단어가 이미 존재하지 않는다면 계속 하십시요.</T>
          </View>)} />
      <Stage
        getTitle={() => '다음 단어들이 이미 추천되어 있습니다.'}
        conditional={'noms'}
        renderContent={(_data, noms: NomItem[]) => {
          return (
            <View>
              <ResList
                style={{paddingBottom: 10}}
                data={noms}
                renderItem={item => RenderDefWithAddedBy(item)}
              />
              <T>만약 원하는 단어가 추천 되어 있지 않다면 계속 하십시요.</T>
            </View>
          );
        }}
      />
      {/* This is a fallback Stage that will get hit if no selections are made so far
          which means that the user wants to nominate a complete new word */}
      <Stage 
        getTitle={data => `${data.word}(으)로 새 단어 추천하기`}
        onData={() => Promise.resolve({_dummy: true})}
        >
        <Inpute label={'뜻'} name='meaning' required />
        <Inpute label={'예제'} name='phrase' multiline required />
      </Stage>
      <Stage
        waitMessage={data => `${data.word}(으)로 새 단어 추천 중입니다....`}
        onData={data => remote.set.Change['NominateWord'](data.word, data.meaning, data.phrase)
          .then(
            () => {
              // todo: show forme final message this way??
              return {finalMessage: `Successfully nominated ${data.word}`};
            }
          )}
        conditional={'_dummy'}
        renderContent={(data) => {
          const styleMsg: StyleProp<TextStyle> = {fontSize: 14, fontWeight: 'bold', color: '#444'};
          const styleLabel: StyleProp<TextStyle> = {fontWeight: 'bold'};
          const stylePhrase: StyleProp<TextStyle> = {marginLeft: 10};
          return (
            <Fragment>
              <T style={styleMsg}>
                추천 된 단어는 검열 후 문제가 없을시 2~3일내로 인싸미터에 등록됩니다{'\n'}
                추천 한 단어가 인싸미터에 등록 된 후에는 삭제가 불가능합니다.{'\n'}
              </T>
              <View style={{borderWidth: 1, backgroundColor: '#FFF', marginVertical: 10, padding: 4}}>
                <View style={{flexDirection: 'row'}}>
                  <T style={styleLabel}>단어: </T>
                  <T>{data.word}</T>
                </View>
                <View style={{flexDirection: 'row'}}>
                  <T style={styleLabel}>뜻: </T>
                  <T>{data.meaning}</T>
                </View>
                <T style={styleLabel}>예제:</T>
                <T style={stylePhrase}>{data.phrase}</T>
              </View>
              <T style={styleMsg}>
                위 정보로 추천을 원하시면 Submit 버튼을 눌러주세요.
              </T>
            </Fragment>
          );
        }}
      />
    </Forme>
  );
}

export function AuthUserBox(props: CustomFormeProps): JSX.Element {
  const {remote} = props;
  return (
    <View style={styles.AuthUser}>
      <CollapsibleBox title={'새 단어 추가하기'}>
        <Forme
          style={{borderWidth: 1, padding: 10, backgroundColor: 'azure'}}
          fontSize={18}
          onSubmit={() => props.onSubmit()}
          renderEnd={(data, custom) => {
            const success = 'success' in custom;
            return (
              <View>
                <T>{`Adding word: ${data.word}`}</T>
                <T>{`  Meaning: ${data.meaning}`}</T>
                <T>{`  Phrase: ${data.phrase}`}</T>
                <T>{''}</T>
                <T style={{fontSize: 18, color: success ? 'green' : 'red'}}>{
                  success
                    ? "Successfully added"
                    : `Error occurred: ${JSON.stringify(custom.error)}`
                }</T>
              </View>
            );
          }}>
          <Stage
            onData={data => remote.set.Change['AddNewWord'](data.word, data.meaning, data.phrase)
              .then(() => { return {success: "Success"}; })}>
            <Inpute label={'단어'} name='word' required />
            <Inpute label={'뜻'} name='meaning' required />
            <Inpute label={'예제'} name='phrase' multiline={true} required />
          </Stage>
        </Forme>
      </CollapsibleBox>
      {/* todo: don't GetAll */}
      <CollapsibleBox title={'Promote nominated'}>
        <ResItem
          load={remote.GetAllNominated}
          render={(noms: NomItem[]) => (
            <Forme onSubmit={() => props.onSubmit()} renderEnd={(_, custom) => {
              let message;
              if (custom.promoteErr.length === 0)
                message = 'Success!';
              else if (!custom.partial)
                message = 'Failed';
              else
                message = 'Partial Success';
  
              const errList = custom.promoteErr.map((errMsg: string, index: number) => (<T key={`promote${index}`}>{errMsg}</T>));
              return (
                <View>
                  <T style={{fontSize: 18, fontWeight: 'bold'}}>{message}</T>
                  {errList}
                </View>
              );
            }}>
              <Stage
                onData={data => {
                  const nomList: FormeDataWithInput<ResData<NomItem>, boolean[]> = data.nomList;
                  if (!nomList.input || !nomList.value)
                    return Promise.resolve({});
  
                  const nomPromote: NomItem[] = [];
                  const ps: Promise<boolean>[] = [];
                  for (let i = 0; i < nomList.value.length; i++) {
                    // if selected
                    if (nomList.value[i]) {
                      const nom = nomList.input[i];
                      nomPromote.push(nom);
                      ps.push(remote.set.Change['PromoteNominated'](nom.id));
                    }
                  }
  
                  return Promise.all(ps).then(res => {
                    const promoteErr: string[] = [];
                    for(let i = 0; i < res.length; i++) {
                      if (!res[i])
                        promoteErr.push(`Unable to promote: ${nomPromote[i].word} [${nomPromote[i].id}]`);
                    }
  
                    return {promoteErr, partial: promoteErr.length < nomPromote.length};
                  });
                }}>
                <Liste
                  label='nomList'
                  input={noms} // make sure that 'data' is different whenever its content changes.
                  renderItem={item => RenderDefWithAddedBy(item)} />
              </Stage>
            </Forme>
          )}
        />
      </CollapsibleBox>
    </View>
  );
}

const styles = StyleSheet.create({
  AuthUser: {
    borderWidth: 1,
    backgroundColor: 'rgba(0,0,0,0.8)',
    padding: 20,
  }
});
