import React, {Fragment} from 'react';
import {ActivityIndicator, View, TouchableOpacity, StyleProp, ViewStyle, TextStyle, Image} from 'react-native';
import {WithId, ShowModal, StyleIcon} from '../lib/commons';
import {IWord, IVotable} from '../lib/db';
import {UserData, SignHelper} from '../lib/firebaseUtil';
import {WordItem, ResManager, MsgItem, SearchHistoryItem, Def, WithAddedBy} from '../lib/remote';

import {T} from './T';
import CollapsibleBox from './collapsibleBox';
import {ResItem} from './resList';
import { GetURLWithParam } from '../page';

// todo
// 1. Show Definitions, highlight the top match at the top.
// 2. Show related/referenced words (allows something similar to wikipedia-Link game).
// 3. Need to use it in sentence
// 99. memo
// maybe:
//  - referenced words

export type HandlerResItem = {selectUser: (uid: string) => void, voteup: () => void, votedown: () => void};
type paramVote = { style: StyleProp<TextStyle>, change?: () => void };

export function RenderLazyWord(item: Readonly<WithId<IWord>>, load: () => Promise<Def>) {
  return (
    <CollapsibleBox renderTitle={() => _renderWord(item, 18)} renderButton={null}>
      <ResItem
        load={load}
        render={item => _renderDictionaryDetail(item, null, 18)}
      />
    </CollapsibleBox>
  );
}

export function RenderDefCollapsible(item: Readonly<Def>, remove?: (item: Readonly<Def>) => void) {
  const jsxFoot = remove
    ? (<TouchableOpacity
        style={{alignSelf: 'flex-end', backgroundColor: '#DDD'}}
        onPress={() => remove(item)}>
        <View style={{width: 100, justifyContent: 'center', alignItems: 'center'}}>
          <T>삭제</T>
        </View>
      </TouchableOpacity>)
    : null;
  return _renderDef(item, jsxFoot);
}

function _renderDef(item: Readonly<Def>, jsxFoot: JSX.Element | null = null) {
  return (
    <View>
      <CollapsibleBox
        renderTitle={() => _renderWord({word: item.word, n: item.n, actual: null})}
        renderButton={null}>
        {_renderDictionaryDetail(
          item,
          jsxFoot,
          24,
          {paddingBottom: 10})}
      </CollapsibleBox>
    </View>
  );
}

export function RenderMsgItem(item: Readonly<MsgItem>) {
  return (
    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
      <T>{item.body}</T>
      <T>{JSON.stringify(item.timestamp)}</T>
    </View>
  );
}

export function RenderDefWithAddedBy(item: WithAddedBy<Def>): JSX.Element {
  return item  === ResManager.Preloading
    ? (<ActivityIndicator size={'small'} style={{padding: 3}} />)
    : (
    <View style={{padding: 4}}>
      {_renderWordWithVote(item, 18, {style: {fontSize: 14, fontWeight: 'bold', color: 'gray'}})}
      {_renderDictionaryDetail(
        item,
        _renderAtSide('', _getAddedBy(item)),
        21,
        {},
        {backgroundColor: 'rgba(0,0,0,0.2)'})}
    </View>);
}

export function RenderResItem(item: WordItem, handle?: HandlerResItem): JSX.Element {
  if (item === ResManager.Preloading) {
    return (<ActivityIndicator size={'large'} style={{padding: 8 /* need at least ceil(18*sqrt(2) - 18) */}} />);
  } else {
    const isLiked = IsLiked(SignHelper.I.GetUserData(), item.id);
    const paramVote = {
      style: isLiked ? {backgroundColor: '#007FFF'} : {backgroundColor: '#BBB'},
      change: !handle ? () => {} : !isLiked ? handle.voteup : handle.votedown
    };

    return _renderDictionary(item, 27, paramVote, _renderAtSide('', _getAddedBy(item, handle ? handle.selectUser : undefined)));
  }
}

export function RenderSearchHistoryItem(item: Readonly<SearchHistoryItem>): JSX.Element {
  const ts = item.timestamp.toDate();
  return (
    <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottomWidth: 1}}>
      <T style={{fontSize: 18, fontWeight: 'bold'}}>{item.query}</T>
      <T style={{color: '#888', fontWeight: 'bold'}}>{`${ts.toLocaleDateString()} ${ts.toLocaleTimeString()}`}</T>
    </View>
  );
}

function IsLiked(userData: UserData, wid: string): boolean {
  return userData.favorite.contains(wid);
}

const _renderWord = ({word, n, actual}: IWord, fontSize: number = 18) => (
  <View style={{flexDirection: 'row'}}>
    <T style={{fontSize, fontWeight: 'bold'}}>{actual ? actual : word}</T>
    {isNaN(n) || n === 0 ? null : (<T style={{fontSize: fontSize/2, textAlignVertical: 'top'}}>{n}</T>)}
  </View>);

const _renderWordWithVote = (item: IWord & IVotable, wordSize: number, paramVote: paramVote) => {
  const jsxVote = (<T style={[{fontSize: 16, color: '#FFF', padding: 4, borderRadius: 8}, paramVote.style]}>{`up: ${item.vote}`}</T>);
  return (
    <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
      {_renderWord(item, wordSize)}
      <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
        <TouchableOpacity onPress={() => navigator.clipboard.writeText(GetURLWithParam({q: item.word})).then(() => ShowModal(`"${item.word}" 페이지 주소를 복사하였습니다.`))}>
          <View style={[StyleIcon, {marginRight: 4}]}>
            <Image style={{width: 22, height: 22}} source={require('../icons/share.png')} />
          </View>
        </TouchableOpacity>
        {paramVote.change !== undefined
          ? (<TouchableOpacity onPress={paramVote.change}>{jsxVote}</TouchableOpacity>)
          : jsxVote}
      </View>
    </View>
  );
};

const _renderAtSide = (left: string, right: JSX.Element, fontSize?: number, key?: string): JSX.Element => (
  <View key={key} style={{flexDirection: 'row', alignItems: 'flex-end', justifyContent:'space-between', borderBottomWidth: 1, borderBottomColor: 'rgba(0,0,0,0.1)'}}>
    <T style={{fontSize}}>{left}</T>
    {/* View here is necessary to keep 'side' from breaking up (word break due to spacing) */}
    <View>
      {right}
    </View>
  </View>
)

// definition style
const _renderDefStyle = (fontSize: number, style: StyleProp<ViewStyle>, render: (fsSml: number, fsMed: number) => React.ReactNode) => {
  return (
    <View style={[style, {flexDirection: 'row'}]}>
      <View style={{marginLeft: 10, width: 10, borderBottomWidth: 1, borderLeftWidth: 1}}></View>
      <View style={{flex: 1}}>
        {render(fontSize*4/9, fontSize*6/9)}
      </View>
    </View>
  );
}

const _renderDictionary = (item: Def, wordSize: number, paramVote: paramVote, jsxFoot: JSX.Element | null = null) => (
  <View>
    {_renderWordWithVote(item, wordSize, paramVote)}
    {_renderDictionaryDetail(item, jsxFoot, 27)}
  </View>
);

const _renderDictionaryDetail = (item: Def, jsxFoot: JSX.Element | null, fontSize = 27, style: StyleProp<ViewStyle> = null, stylePhrase: StyleProp<ViewStyle> = null) => 
  _renderDefStyle(
    fontSize,
    style,
    (fsSml, fsMed) => (
      <Fragment>
        <T style={{fontSize: fsMed}}>{item.meaning}</T>
        <View style={[{paddingVertical: 10, paddingLeft: 10}, stylePhrase]}>
          <T>예제: </T>
          {item.phrase.map((ph, index) => (<T style={{fontSize: fsSml}} key={`${item.word}-${item.n}-${index}`}>{ph}</T>))}
        </View>
        {jsxFoot}
      </Fragment>));

const _getAddedBy = (item: WithAddedBy<Def>, onSelect?: (uid: string) => void): JSX.Element => {
  if (!item.addedBy)
    return (<T>{'by unknown'}</T>);

  return (
    <View style={{flexDirection: 'row'}}>
      <T>by </T>
      <TouchableOpacity onPress={onSelect ? () => onSelect(item.uid) : () => {}}>
        <T>{item.addedBy}</T>
      </TouchableOpacity>
      <T>{` ${item.timestamp.toDate().toLocaleDateString()}`}</T>
    </View>
  );
}