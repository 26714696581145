import React from 'react';
import Remote, {WordItem} from '../lib/remote';
import {RenderResItem} from './resultBox';

type SSStates<T> = {
  data: T
};

type SSProps<T> = {
  data: Readonly<T>,
  remote: Remote,
};

abstract class SS<T, P extends SSProps<T>> extends React.Component<P, SSStates<T>> {
  protected get remote() {
    return this.props.remote;
  }

  protected get data() {
    return this.state.data;
  }

  constructor(props: P) {
    super(props);

    this.state = {
      data: props.data
    }

    // props.register(dataNew => this.setState({data: dataNew}));
  }

  protected _refresh = () => this.setState({data: this.state.data});
}

type ResSSProp = SSProps<WordItem> & {
  onSelectUser: (uid: string) => void,
  highlightLogin: () => void,
};

export class ResItemSS extends SS<WordItem, ResSSProp> {
  render() {
    return RenderResItem(this.data, {
      selectUser: this.props.onSelectUser,
      voteup: () => this.remote.set
        .Change['VoteupDictionary'](this.data)
        .then(isSuccess => {
          if (isSuccess)
            this._refresh();
          else // probably check for other errors here actually.
            this.props.highlightLogin();
        }),
      votedown: () => this.remote.set
        .Change['VotedownDictionary'](this.data)
        .then(isSuccess => {
          if (isSuccess)
            this._refresh();
          else // this can't happen when you are not logged in since you shouldn't even be allowed to down vote (only allow up vote).
            console.error("Could not down vote");
        })
    });
  }
}