import React, {Fragment} from 'react';
import {View, Text, StyleSheet, TouchableOpacity, Image} from 'react-native';

import {IWord} from '../lib/db';
import {WithId} from '../lib/commons';
import {SignHelper, UserInfo} from '../lib/firebaseUtil';

import CollapsibleBox, { ButtonStyle } from './collapsibleBox';
import Timed, {TimedParam} from './timed';
import {T} from './T';
import Remote, {MsgItem, Def, SearchHistoryItem} from '../lib/remote';
import { RenderLazyWord, RenderDefCollapsible, RenderMsgItem, RenderSearchHistoryItem} from './resultBox';
import { ResList, ResData} from './resList';
import { OptionalBox } from './box';
import { LogoBox, LoginBox, NominateForm, AuthUserBox } from './singleton';

interface UBProps {
  remote: Remote,
}

interface UBStates {
  show: Show;

  // undefined: not checked yet
  //  1. null: no data available
  //  2. []: Loading data
  //     if no data !(data.length > 0): set to null
  data: {
    [UserInfo.Added]: ResData<Def>,
    [UserInfo.Favorite]: ResData<WithId<IWord>>,
    [UserInfo.Nominated]: ResData<Def>,
    [UserInfo.Message]: ResData<MsgItem>,
    [UserInfo.SearchHistory]: ResData<SearchHistoryItem>,
  };

  isAuthorized: boolean; // on top of this, we'll always check for the authentication whenever we do something

  highlightable: {[k in MsgHighlight]: Msg};
}

enum Show {
  Login = 0,
  UserInfo = 1,
  NominateForm = 2,
  List = 3,
  AuthUser = 4
}

enum MsgHighlight {
  Message,
  LoginBox,
}

// Turn this into a class to be used everywhere.
class Msg {
  public highlight: MsgHighlight;
  public content: string;

  public get isEmpty() {
    return this === Msg.Empty;
  }

  public get getKey() {
    return this._param.getKey;
  }

  public get duration() {
    return this._param.duration;
  }

  public get color() {
    return this._param.obj;
  }

  public get timedParam(): TimedParam<string> | undefined {
    return this.isEmpty ? undefined : this._param;
  }

  private _param: TimedParam<string>;
  private _ts: number;

  constructor(highlight: MsgHighlight, content: string, color: string, duration: number) {
    this.highlight = highlight;
    this.content = content;
    this._param = {
      getKey: () => this._ts.toString(),
      duration: duration,
      obj: color,
    };

    this._ts = Date.now();
  }

  static Empty = new Msg(MsgHighlight.Message, '', 'black', 0);
  static Error = (content: string, highlight: MsgHighlight = MsgHighlight.Message) => new Msg(highlight, content, "salmon", 4000);
  static Success = (content: string, highlight: MsgHighlight = MsgHighlight.Message) => new Msg(highlight, content, "green", 2000);
}

const rendererFromUserInfo: {[k in UserInfo]: (item: Readonly<any>, remote: Remote, onUpdate: () => void) => JSX.Element} = {
  [UserInfo.Added]: (item) => RenderDefCollapsible(item),
  [UserInfo.Favorite]: (item, remote) => RenderLazyWord(item, () => remote.GetWordById(item.id)),
  [UserInfo.Message]: RenderMsgItem,
  [UserInfo.Nominated]: (item, remote, onUpdate) => RenderDefCollapsible(item, item => {
    remote.set.Remove('RemoveNominated', {id: item.id})
      .then(isRemoved => {
        if (isRemoved)
          onUpdate();
      })
  }),
  [UserInfo.SearchHistory]: RenderSearchHistoryItem,
};

export default class UserBox extends React.Component<UBProps, UBStates> {
  static isCollapsedDefault = true;

  private _refCBMain: React.RefObject<CollapsibleBox>;
  private _refCBNom: React.RefObject<CollapsibleBox>;

  constructor(props: UBProps) {
    super(props);

    this._refCBMain = React.createRef();
    this._refCBNom = React.createRef();

    this.state = {
      data: {
        [UserInfo.Nominated]: undefined,
        [UserInfo.Favorite]: undefined,
        [UserInfo.Added]: undefined,
        [UserInfo.Message]: undefined,
        [UserInfo.SearchHistory]: undefined,
      },
      show: Show.Login,
      isAuthorized: false,
      highlightable: {
        [MsgHighlight.Message]: Msg.Empty,
        [MsgHighlight.LoginBox]: Msg.Empty,
      },
    }

    SignHelper.I.OnUserChange((uidNew: string | null, uidOld: string | null) : void => {
      if (uidNew) {
        this._onStateChange(Msg.Success('Login Successful'), {isAuthorized: false});
        this.props.remote.GetAuthority().then(isAuthorized => this.setState({isAuthorized}));
      } else if (uidOld) {
        this._onStateChange(
          Msg.Success('Logout Successful'),
          {
            isAuthorized: false,
            data: {
              [UserInfo.Nominated]: undefined,
              [UserInfo.Favorite]: undefined,
              [UserInfo.Added]: undefined,
              [UserInfo.Message]: undefined,
              [UserInfo.SearchHistory]: undefined,
            }
          }
        );
      }
    });
  }

  public ShowNominateForm() {
    const refMain = this._refCBMain.current;
    if (refMain && refMain.IsEnabled()) {
      // ensure that collapsible box is opened.
      refMain.Open().then(isCollapsed => {
        if (!isCollapsed) {
          const refNom = this._refCBNom.current;
          if (refNom)
            refNom.Open();
        }
      });
    } else {
      this.HighlightLogin();
    }
  }

  public HighlightLogin = () => this._onStateChange([Msg.Error('', MsgHighlight.LoginBox), Msg.Error('로그인이 필요한 기능입니다')]);

  _onStateChange<A extends keyof UBStates> (msg: Msg[] | Msg, state?: Pick<UBStates, A>) {
    if (state)
      this.setState(state);

    const highlightable = this.state.highlightable;
    if ('forEach' in msg) {
      msg.forEach(m => highlightable[m.highlight] = m);
    } else
      highlightable[msg.highlight] = msg;

    this.setState({highlightable});
  }

  _updateMyList = (userInfoType: UserInfo) => {
    if (!SignHelper.I.HasUser())
      return;

    const {data} = this.state;
    ResList.populate<any>( // ts doesn't handle this correctly.
      data[userInfoType],
      listDataNew => {
        data[userInfoType] = listDataNew;
        this.setState({data});
      },
      () => this.props.remote.GetUserOwnedItems(userInfoType));
  }

  _renderMyList = (title: string, userInfoType: UserInfo, renderFooter?: () => JSX.Element) => {
    return this._renderList<any>( // ts doesn't handle this correctly.
      title,
      this.state.data[userInfoType],
      () => this._updateMyList(userInfoType),
      item => rendererFromUserInfo[userInfoType](item, this.props.remote, () => this._updateMyList(userInfoType)),
      renderFooter
    );
  }

  _renderList<T>(
    title: string,
    data: ResData<WithId<T>>,
    updateList: () => void,
    renderItem: (item: Readonly<WithId<T>>) => JSX.Element,
    renderFooter?: () => JSX.Element) {
    return (<CollapsibleBox
      style={{marginTop: 10}}
      title={title}
      onChange={isCollapsed => {
        if (!isCollapsed)
          updateList();
      }}>
        <View style={styles.ListView}>
          <ResList
            style={styles.ListViewContent}
            data={data}
            onGetMore={()=>{}}
            renderItem={item => renderItem(item)}
            renderOnNull={() => (<T>아무 결과가 없습니다.</T>)}
            renderFooter={renderFooter}
            keyExtractor={item => item.id}
          />
        </View>
      </CollapsibleBox>);
  }

  _renderOrDefault = (doRender: boolean, render: () => JSX.Element) => doRender ? render() : (<View></View>);

  // https://stackoverflow.com/questions/52700659/how-to-use-enum-as-index-key-type-in-typescript
  // Index signature only takes in number of string as key.
  // enums must be computed properties for it to be used as key.
  _renderUserInfo = () => (
    <Fragment>
      <View style={{padding: 18}}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <T style={{fontSize: 18}}>{'Display Name:'}</T>
          <T style={{fontSize: 18, fontWeight: 'bold'}}>{SignHelper.I.GetDisplayName()}</T>
        </View>
        <OptionalBox isVisible={SignHelper.I.HasUser()}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <T style={{fontSize: 18}}>{'Email:'}</T>
            <T style={{fontSize: 18, fontWeight: 'bold'}}>{SignHelper.I.GetEmail()}</T>
          </View>
          <View style={{flexDirection: 'row', justifyContent: 'flex-end', marginTop: 18}}>
            <TouchableOpacity onPress={SignHelper.I.Logout}>
              <T style={{fontWeight: 'bold', padding: 4, borderWidth: 1, backgroundColor:'rgba(255,255,255,0.8)'}}>LogOut</T>
            </TouchableOpacity>
          </View>
        </OptionalBox>
      </View>
      {/* {this._renderMyList('메세지', UserInfo.Message)} */}
      <OptionalBox isVisible={SignHelper.I.HasUser()}>
        {this._renderMyList('내가 좋아하는 단어 (Favorite Words)', UserInfo.Favorite)}
        {this._renderMyList('내가 추가한 단어 (Added Words)', UserInfo.Added)}
        {this._renderMyList('내가 추천한 단어 (Nominated Words)', UserInfo.Nominated)}
      </OptionalBox>
      {this._renderMyList('최근검색 (Search History)', UserInfo.SearchHistory, () => (
        <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
          <TouchableOpacity
            style={{width: 100, justifyContent: 'center', alignItems: 'center', padding: 4}}
            onPress={() => this.props.remote.set.Remove('RemoveSearchHistory').then(() => {
              this._updateMyList(UserInfo.SearchHistory);
            })}>
            <T style={{fontWeight: 'bold', fontSize: 14, color: '#444'}}>모든 기록 삭제 (Delete all)</T>
          </TouchableOpacity>
        </View>
      ))}
      <CollapsibleBox ref={this._refCBNom} title={'단어 추가하기 (Nominate)'} style={{marginTop: 10}}>
        <NominateForm remote={this.props.remote} onSubmit={() => this._updateMyList(UserInfo.Nominated)} highlightLogin={this.HighlightLogin} />
      </CollapsibleBox>
      <OptionalBox isVisible={this.state.isAuthorized}>
        <CollapsibleBox title={'[Advanced User]'} style={{marginTop: 20}}>
          <AuthUserBox remote={this.props.remote} onSubmit={() => {
            this._updateMyList(UserInfo.Nominated);
            this._updateMyList(UserInfo.Added);
          }} />
        </CollapsibleBox>
      </OptionalBox>
    </Fragment>
  );

  render() {
    const loginBox = this.state.highlightable[MsgHighlight.LoginBox];
    const message = this.state.highlightable[MsgHighlight.Message];

    return (
      <CollapsibleBox
        ref={this._refCBMain}
        style={styles.UserBoxMain}
        isCollapsedDefault={UserBox.isCollapsedDefault}
        enable={SignHelper.I.HasSomeUser()}
        buttonStyle={ButtonStyle.Stuck}
        renderTitle={() => {
          return (
            <View style={{flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', height: 40}}>
              <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                <LogoBox />
                {message.isEmpty
                  ? null
                  : (
                    <Timed keyTime={message.getKey()} duration={message.duration}>
                      <View style={{paddingHorizontal: 10}}>
                        <Text style={{color: message.color}}>{message.content}</Text>
                      </View>
                    </Timed>
                  )}
              </View>
              {!SignHelper.I.HasUser() ? (<LoginBox highlight={loginBox.isEmpty ? undefined : loginBox.timedParam} />) : null}
            </View>
          )
        }}
        renderButton={() => (
          <View style={{flexDirection: 'row', alignItems:'flex-end'}}>
            <T style={{fontSize: 18, fontWeight: 'bold', marginHorizontal: 10}}>{SignHelper.I.GetDisplayName()}</T>
            <View>
              <Image style={{width: 18, height: 18, margin: 2}} source={require('../icons/menu.png')} />
            </View>
          </View>
        )}
        >
        <View style={styles.Expanded}>
          {SignHelper.I.HasSomeUser() ? this._renderUserInfo() : null}
        </View>
      </CollapsibleBox>
    );
  }
}

const styles = StyleSheet.create({
  Expanded: {
    backgroundColor: 'aliceblue',
    borderWidth: 1,
    marginTop: 2,
    paddingHorizontal: 4,
    paddingTop: 10,
    paddingBottom: 37, // 10 + ButtonOverlapHeight
  },
  UserBoxMain: {
    marginTop: 8,
    marginBottom: 8,
    minHeight: 30,
  },
  ListView: {
    backgroundColor: 'beige',
    borderWidth: 1,
  },
  ListViewContent: {
    maxHeight: 400,
    paddingVertical: 10, 
    paddingHorizontal: 27,
  },
});