import {CacheKey, IHaveKey, KeyedData} from './commons';

// T is a class with one argument to its constructor.
// technically should be Cache<A, B, C extends Manager<A, B>>
export default class Cache<K extends CacheKey, T> {
  private static cacheDuration = 3600000; // in ms 60*60*1000 = 3600000

  private ts: Date;
  private data: KeyedData<T>;

  private c: new (dep: K) => T;

  constructor(c: new (dep: K) => T) {
    this.ts = new Date();
    this.data = {};
    this.c = c;
  }

  public Get(dep: K) : T {
    this._reset();

    // for some reason, TS can't figure out that dep will be IHaveKey if not typeof string.
    const k = typeof dep === "string" ? dep : (dep as IHaveKey).GetKey();
    if (!this.data.hasOwnProperty(k))
      this.data[k] = new this.c(dep);

    return this.data[k];
  }

  private _reset() {
    const tsNow = new Date();
    if (tsNow.getTime() - this.ts.getTime() > Cache.cacheDuration)
    {
      this.ts = tsNow;
      this.data = {};
    }
  }
}