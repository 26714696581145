import firebase from 'firebase/app';
import * as tdb from './db';
import {KeyedArray, WithId, AsBoolean, EncodeDocId, DecodeDocId} from './commons';
import {stringFromDate, DateName} from './stat';

require('firebase/auth');
require('firebase/firestore');
require('firebase/functions');

firebase.initializeApp({
  apiKey: "AIzaSyBB9jqpemYYXkfXoC1z5AdApAD7PB-PcPo",
  authDomain: "insiderdictionary.firebaseapp.com",
  databaseURL: "https://insiderdictionary.firebaseio.com",
  projectId: "insiderdictionary",
  storageBucket: "insiderdictionary.appspot.com",
  messagingSenderId: "908051026590",
  appId: "1:908051026590:web:746f2e560fda8ff027618f",
  measurementId: "G-73K18MJDSC"
});

const db = firebase.firestore();
const fn = firebase.functions();
const docId = firebase.firestore.FieldPath.documentId();

export const defaults: {
  timestamp: firebase.firestore.Timestamp,
  geopoint: firebase.firestore.GeoPoint,
} = {
  timestamp: new firebase.firestore.Timestamp(0, 0),
  geopoint: new firebase.firestore.GeoPoint(0, 0),
}

export enum Size {
  Small,
  Medium,
  Large,
}

export enum UserInfo {
  Added,
  Favorite,
  Nominated,
  Message,
  SearchHistory,
}

export type SnapshotResult<T> = Promise<{res: T, unsubscribe?: () => void}>;
export type StatWordItem = WithId<{word: string}> & tdb.ICount;

// todo: use this.
// enum CN {
//   User,
//   Dictionary,
//   DictionaryHint,
//   Nominated,
//   MyAddedList,
//   MyFavoriteList,
// }

function _getDocsInRange(colName: string, range: string[], n: number, itemLast: firebase.firestore.DocumentSnapshot | null) {
  const refCol = db.collection(colName);
  let query = refCol
    .where(docId, '>=', refCol.doc(EncodeDocId(range[0])))
    .where(docId, '<', refCol.doc(EncodeDocId(range[1])));
  
  return _queryAfterLast(query, n, itemLast);
}

export function GetHintItems(range: string[], n: number, itemLast: firebase.firestore.DocumentSnapshot | null) {
  return _getDocsInRange('DictionaryHint', range, n, itemLast);
}

export function GetDictionary(q: string){
  return db.collection('Dictionary').where('word', '==', q).orderBy('n', 'asc').get();
}

export function GetDictionaryById(id: string): Promise<firebase.firestore.DocumentSnapshot> {
  return db.collection('Dictionary').doc(EncodeDocId(id)).get();
}

export function GetUserDoc(uid: string) {
  return db.collection('User').doc(EncodeDocId(uid)).get();
}

export function GetWordsByUid(uid: string) {
  return _getWordsByUid<tdb.Dictionary>(uid, db.collection('Dictionary'));
}

function _getWordsByUid<T>(uid: string, colRef: firebase.firestore.CollectionReference) : Promise<WithId<T>[]> {
  return colRef
  .where('uid', '==', uid)
  .orderBy('word')
  .orderBy('n')
  .get()
  .then(qs => ReadFromQSWithId(qs));
}

export function GetUserMessage(uid: string) : Promise<WithId<tdb.UserMessage>[]> {
  return db.collection(`User/${uid}/Inbox`).get().then(qs => ReadFromQSWithId(qs));
}

function GetUserCollection(docRefUser: firebase.firestore.DocumentReference, colName: string): Promise<WithId<tdb.IWord>[]> {
  return docRefUser.collection(colName).get().then(qs => ReadFromQSWithId<tdb.IWord>(qs));
}

function GetUserData(uid: string): Promise<UserData> {
  const docRefUser = db.collection('User').doc(EncodeDocId(uid));
  return docRefUser.get().then(doc => {
    const data = doc.data() as tdb.User;

    // must make sure that _userData is all cleared.
    // but it should've been done on signout.
    const userData: UserData = {favorite: new KeyedArray(), alias: data.alias};
    
    return Promise.all([
      GetUserCollection(docRefUser, 'favorite').then(favs => {
        userData.favorite.set(favs);
      })
    ]).then(() => userData);
  });
}

// todo: should probably update score when we look up a nominated word (b/c it indicates an interest in the word).
// todo: handle n
// todo: add id.
export function GetNominated(q: string) {
  return db.collection('Nominated')
    .where('word', '==', q)
    .orderBy('lastModified')
    .get();
}

export function GetNominatedByUid(uid: string) {
  return _getWordsByUid<tdb.Nominated>(uid, db.collection('Nominated'));
}

// don't order by word, I just want oldest one first
// or actually the highest score one.
export function GetAllNominated(): Promise<WithId<tdb.Nominated>[]> {
  return db.collection('Nominated')
    .orderBy('score', 'desc')
    .orderBy('vote', 'desc')
    .orderBy('lastModified')
    .get()
    .then(qs => ReadFromQSWithId(qs));
}

function _nominatedData(uid: string, actual: string | null, lowercase: string, meaning: string, phrase: string): tdb.Nominated {
  return {
    word: lowercase,
    actual,
    n: 0, // dummy value for GetMyList (b/c other types use the field n)
    timestamp: firebase.firestore.FieldValue.serverTimestamp() as any,
    lastModified: firebase.firestore.FieldValue.serverTimestamp() as any,
    meaning: meaning,
    phrase: [phrase],
    uid,
    vote: 0,
    score: 0,
  };
}

export function GetSearchHistory(uid: string, n: number, itemLast: firebase.firestore.DocumentSnapshot | null) {
  let query = db.collection('User').doc(EncodeDocId(uid)).collection('history').orderBy('timestamp', 'desc');
  return _queryAfterLast(query, n, itemLast);
}

export const GetStatMethods = {
  RecentlyAdded: (n: number) => db.collection('Dictionary').orderBy('timestamp', 'desc').limit(n).get().then(qs => ReadFromQSWithId<tdb.Dictionary>(qs)),
  MostVoted: (n: number) => db.collection('Dictionary').orderBy('vote', 'desc').orderBy('timestamp', 'desc').limit(n).get().then(qs => ReadFromQSWithId<tdb.Dictionary>(qs)),
  PopularAllTime: (n: number) => db.collection('Stat').orderBy('count', 'desc').limit(n).get().then(qs => ReadFromQSWithId<any>(qs)),
  PopularByDate: (n: number, date: Date) => ToDateName(date).then(dn => _getPopularWord(n, `countMap.${dn.year}.${dn.month}.${dn.date}`)),
  PopularByMonth: (n: number, date: Date) => ToDateName(date).then(dn => _getPopularWord(n, `countMap.${dn.year}.${dn.month}.count`)),
  PopularByYear: (n: number, date: Date) => ToDateName(date).then(dn => _getPopularWord(n, `countMap.${dn.year}.count`)),
}

function ToDateName(date: Date) : Promise<DateName> {
  return Promise.resolve(stringFromDate(date));
}

function _getPopularWord(n: number, fieldName: string) {
  return db.collection('Stat').where(fieldName, '>=', 0).orderBy(fieldName, 'desc').limit(n).get().then(qs => ReadFromQSWithId<any>(qs));
}

type addDictResult = {data: tdb.Dictionary, ref: firebase.firestore.DocumentReference};
function _addDictionary(transaction: firebase.firestore.Transaction, uid: string, actual: string | null, lowercase: string, meaning: string, phrase: string): Promise<addDictResult> {
  const docRefHint = db.collection('DictionaryHint').doc(EncodeDocId(lowercase));
  const docRefWordNew = db.collection('Dictionary').doc();

  return transaction.get(docRefHint).then(doc => {
    const dictHintDataNew: tdb.DictionaryHint = {
      numDef: 0, // this will get incremented if hint doesn't exist already.
      timestamp: firebase.firestore.FieldValue.serverTimestamp() as any,
      actual,
    }

    if (doc.exists) {
      const dictHintData = doc.data() as tdb.DictionaryHint;

      // Must transfer all data that need to be kept.
      // i.e. timestamp will get updated.
      dictHintDataNew.numDef = dictHintData.numDef;
    }

    // by adding this word, numDef gets incremented
    dictHintDataNew.numDef++;

    // if docRefHint didn't exist, it will be created anew.
    transaction.set(docRefHint, dictHintDataNew);

    const wordData: tdb.Dictionary = {
      uid,
      word: lowercase,
      n: dictHintDataNew.numDef,
      timestamp: firebase.firestore.FieldValue.serverTimestamp() as any,
      meaning,
      phrase: [phrase],
      vote: 0,
      actual,
    };

    transaction.set(docRefWordNew, wordData);
    return {data: wordData, ref: docRefWordNew};
  })
}

export function RemoveNominated(id: string) {
  return db.collection('Nominated').doc(EncodeDocId(id)).delete();
}

export const RecordMethods = {
  AddHistoryOnSearch: (uid: string, query: string) => {
    if (!uid || typeof query !== 'string' || query === '')
      throw new Error('User not logged in');

    return db.collection('User').doc(EncodeDocId(uid)).collection('history').add({
      query,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    });
  },
  OnLoadWord: (word: string) => {
    fn.httpsCallable('onLoadWord')({word, time: stringFromDate(new Date())});
  }
}

export const ChangeMethods = {
  AddDictionary: (uid: string, word: string, meaning: string, phrase: string) => {
    const lowercase = word.toLocaleLowerCase();
    const actual = lowercase === word ? null : word;
    return db.runTransaction(transaction => _addDictionary(transaction, uid, actual, lowercase, meaning, phrase));
  },
  AddNominated: (uid: string, word: string, meaning: string, phrase: string) => {
    const lowercase = word.toLocaleLowerCase();
    const actual = lowercase === word ? null : word;
    return db.collection('Nominated').add(_nominatedData(uid, actual, lowercase, meaning, phrase));
  },
  PromoteNominated: (id: string) => {
    const docRefNominated = db.collection('Nominated').doc(EncodeDocId(id));
    return AsBoolean(
      db.runTransaction(transaction => transaction.get(docRefNominated).then(async doc => {
        if (doc.exists) {
          const nom = doc.data() as tdb.Nominated;
          // for some reason, if I don't await this (i.e. do transaction.delete inside then)
          // firestore will throw error with transaction.
          await _addDictionary(transaction, nom.uid, nom.actual, nom.word, nom.meaning, nom.phrase[0]);
          transaction.delete(docRefNominated);
        }
      }))
    );
  },
  // using uid as the id ensures that no one can like the same instance more than once.
  VoteupDictionary: (uid: string, wid: string) => {
    return db.collection('Dictionary').doc(EncodeDocId(wid)).collection('liked').doc(EncodeDocId(uid)).set({});
  },
  VotedownDictionary: (uid: string, wid: string) => {
    return db.collection('Dictionary').doc(EncodeDocId(wid)).collection('liked').doc(EncodeDocId(uid)).delete();
  },
}

export function RemoveSearchHistory(uid: string) {
  console.log(`Remove Search ?History for ${uid}`);
  if (!uid)
    return Promise.resolve();
  
  return fn.httpsCallable('deleteAllSearchHistory')({uid});
}

function ReadFromQSWithId<T>(qs: firebase.firestore.QuerySnapshot): WithId<T>[] {
  const res: WithId<T>[] = [];
  qs.docs.forEach(doc => {
    const data = ReadFromDSWithId<T>(doc);
    if (data)
      res.push(data);
  })
  return res;
}

function ReadFromDSWithId<T>(ds: firebase.firestore.DocumentSnapshot): WithId<T> {
  const data = ds.data() as WithId<T>;
  if (data)
    data.id = DecodeDocId(ds.id);
  return data;
}

function _queryAfterLast(query: firebase.firestore.Query, n: number, itemLast: firebase.firestore.DocumentSnapshot | null) {
  if (itemLast != null)
    query = query.startAfter(itemLast);

  return query.limit(n).get();
}

type UserChangeHandler = (uidNew: string | null, uidOld: string | null) => void;
type User = firebase.User | null;

export type UserData = {
  favorite: KeyedArray<WithId<tdb.IWord>>,
  alias: string
};

export class SignHelper {
  // Singleton
  public static I = new SignHelper();

  private _user: User;
  private _userChangeHandlers: UserChangeHandler[];
  private _userDataChangeHandlers: (()=>void)[];

  private _userData: UserData;

  constructor() {
    this._user = firebase.auth().currentUser;
    this._userChangeHandlers = [];
    this._userDataChangeHandlers = [];
    this._userData = {favorite: new KeyedArray(), alias: ''};

    firebase.auth().onAuthStateChanged(user => this._handlerUserChange(user));
    firebase.auth().useDeviceLanguage();
    firebase.auth().getRedirectResult().then(this._onAfterSignIn);
  }

  public HasUser = (): boolean => !!this._user && !this._user.isAnonymous;
  public HasSomeUser = (): boolean => !!this._user;
  public IsAnonymous = (): boolean => !!this._user && this._user.isAnonymous;

  public GetDisplayName = (): string => {
    if (this._user) {
      if (this._user.isAnonymous)
        return this._getAnonymAlias(this._user.uid);
      else
        return this._user.displayName ? this._user.displayName : '<NoName>';
    } else {
      return "NotLoggedIn";
    }
  }
  public GetEmail = (): string => this._user && this._user.email ? this._user.email : 'None';

  // todo: verify?
  public GetUserToken = (): string => this._user ? this._user.uid : '';

  public GetUserData = (): UserData => this._userData;

  // expand this as we add options for different provider.
  public TrySignIn = (redirect: boolean) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    this._trySignIn(redirect, provider);
  }

  public Logout = () => {
    if (this.HasSomeUser()) {
      firebase.auth().signOut();
    }
  }

  public OnUserChange(changeHandler: UserChangeHandler) {
    this._userChangeHandlers.push(changeHandler);
  }

  public OnUserDataChange(onUserDataChange: () => void) {
    this._userDataChangeHandlers.push(onUserDataChange);
  }

  public GetFavorite(): WithId<tdb.IWord>[] {
    return this._userData.favorite.data.filter(d => d !== null) as WithId<tdb.IWord>[];
  }

  public AddFavorite(fav: WithId<tdb.IWord>) {
    this._userData.favorite.add(fav);
  }

  public RemoveFavorite(fav: WithId<tdb.IWord>) {
    this._userData.favorite.delete(fav);
  }

  private _handlerUserChange = (userNew: User) => {
    const userOld = this._user;
    this._user = userNew;

    // always signed in as anonym after signing out first.
    if (userOld || !userNew || !userNew.isAnonymous)
      this._userChangeHandlers.forEach(fn => fn(SignHelper._uidFromUser(userNew), SignHelper._uidFromUser(userOld)));

    if (userNew)
      this._onSignin(userNew);
    else
      this._onSignout();
  }

  private _onSigninHelper = (userData: UserData) => {
    this._userData = userData;
    this._userDataChangeHandlers.forEach(fn => fn());
  }

  private _onSignin = (_user: firebase.User) => {
    if (_user.isAnonymous) {
      this._onSigninHelper({
        favorite: new KeyedArray(),
        alias: this._getAnonymAlias(_user.uid),
      });
    } else {
      GetUserData(_user.uid).then(this._onSigninHelper);
    }
  }

  private _onSignout = () => {
    this._userData.favorite.clear();
    this._userData.alias = '';

    this._userDataChangeHandlers.forEach(fn => fn());
  }

  private _trySignIn(redirect: boolean, provider: firebase.auth.AuthProvider) {
    if (redirect) {
      if (!!this._user && this._user.isAnonymous)
        this._user.linkWithRedirect(provider);
      else
        firebase.auth().signInWithRedirect(provider);
    } else {
      ((!!this._user && this._user.isAnonymous)
        ? this._user.linkWithPopup(provider)
        : firebase.auth().signInWithPopup(provider))
        .then(this._onAfterSignIn);
    }
  }

  private _onAfterSignIn = (_userCred: firebase.auth.UserCredential) => {};

  private static _uidFromUser = (user: User) => user ? user.uid : null;

  private _getAnonymAlias = (uid: string) => `유저-${uid.substring(0, 4)}`;
}